import React, { useEffect } from 'react';
import {
  Button,
  ButtonVariant,
  Link,
  Loader,
  LoaderSize,
} from 'components/common';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { toastError } from 'helpers/toast';
import { IResponseError } from 'types';
import EditBrandForm from './EditBrandForm';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useGetBrandById } from 'apis/brands';
import moment from 'moment';
import cn from 'classnames';

const BrandEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading, isError, error } = useGetBrandById(params?.id);

  useEffect(() => {
    if (isError && error) {
      const err = error as IResponseError;
      toastError(`${err?.response.data.message}`);
    }
  }, [isError, error]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">Edit brand: {params?.id}</h1>
      <Button
        variant={ButtonVariant.text}
        onClick={() => navigate('/brands')}
        prefixIcon={ChevronLeftIcon}
        className="mb-4"
      >
        Back
      </Button>
      {isLoading && !data ? (
        <div className="w-full flex items-center justify-center h-80">
          <Loader size={LoaderSize.xxl} />
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          {data && <EditBrandForm brand={data} id={params?.id} />}
          <div className="w-96 bg-white rounded-lg shadow p-6 max-h-[334px]">
            <h3 className="mb-4">Reports ({data?.reports?.length})</h3>
            <PerfectScrollbar className={'h-[calc(100%-20px)]'}>
              {data?.reports?.map((report) => (
                <div
                  key={report.id}
                  className="py-2 border-b border-surface-div flex items-center justify-between"
                >
                  <Link
                    href={`${
                      process.env.REACT_APP_FRONT_END_URL ||
                      'https://get.rammp.com'
                    }/publicReport?id=${report.id}`}
                    target="_blank"
                  >
                    Report Generated on{' '}
                    {moment(report.created_at).format('YYYY-MM-DD')}
                  </Link>
                  <div
                    className={cn(
                      'font-bold pr-4',
                      (report.scores?.rammpScore || 0) > 80.7
                        ? 'text-rammp-purple'
                        : 'text-rammp-orange',
                    )}
                  >
                    {report.scores?.rammpScore || 0}
                  </div>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandEdit;
