import React from 'react';
import { IconWrapper, Link, Tooltip } from 'components/common';
import { Widget } from 'apis/widgets';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';

const EditWidgetForm = ({ widget }: { widget: Widget }) => {
  return (
    <div className="w-96 bg-white rounded-lg shadow p-6 ">
      <div className="space-y-4">
        <h3>Widget Information</h3>
        <div>
          <div className="mb-1 text-content-secondary font-medium">Owner</div>
          <div className='mb-3'>
            {widget.user ? (
              <Link
                asRouterLink
                routerLinkProps={{
                  to: `/users/${widget.user_id}`,
                }}
                className="truncate"
              >
                {widget?.user?.email}
              </Link>
            ) : (
              <span className="font-bold">User deleted</span>
            )}
          </div>
          <div className="mb-1 text-content-secondary font-medium">
            Agency name
          </div>
          <div className="font-bold mb-3">{widget.agencyName}</div>
          <div className="mb-1 text-content-secondary font-medium">
            Agency Url
          </div>
          <div className="font-bold mb-3">{widget.agencyUrl}</div>
        </div>
        <div>
          <div className="mb-2 text-content-secondary font-medium">
            Domains Whitelisted{' '}
            <Tooltip
              title="The user can only embed the widget to their whitelisted domains"
              className="ml-2 !inline-block"
            >
              <IconWrapper icon={InfoIcon} className="text-content-secondary" />
            </Tooltip>
          </div>
          <div>
            {widget.domains.map((domain) => (
              <Link href={domain.url} target="_blank" className="truncate mb-1">
                {domain.url}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWidgetForm;
