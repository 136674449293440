import {
  Home,
  Login,
  UserManagement,
  UserEdit,
  UserCreate,
} from 'components/pages';
import BrandEdit from 'components/pages/BrandEdit/BrandEdit';
import Brands from 'components/pages/Brands/Brands';
import { Route } from 'react-router-dom';
import Widgets from 'components/pages/Widgets/Widgets';
import WidgetEdit from 'components/pages/WidgetEdit/WidgetEdit';
import Plans from 'components/pages/Plans/Plans';
import PlanEdit from 'components/pages/PlanEdit/PlanEdit';

const Routes = [
  {
    path: '/login',
    caseSensitive: true,
    component: <Login />,
  },
  {
    path: '/',
    caseSensitive: true,
    component: <Home />,
  },
  {
    path: '/users',
    caseSensitive: true,
    component: <UserManagement />,
  },
  {
    path: '/users/:id',
    caseSensitive: true,
    component: <UserEdit />,
  },
  {
    path: '/users/create',
    caseSensitive: true,
    component: <UserCreate />,
  },
  {
    path: '/brands',
    caseSensitive: true,
    component: <Brands />,
  },
  {
    path: '/brands/:id',
    caseSensitive: true,
    component: <BrandEdit />,
  },
  {
    path: '/widgets',
    caseSensitive: true,
    component: <Widgets />,
  },
  {
    path: '/widgets/:id',
    caseSensitive: true,
    component: <WidgetEdit />,
  },
  {
    path: '/plans',
    caseSensitive: true,
    component: <Plans />,
  },
  {
    path: '/plans/:id',
    caseSensitive: true,
    component: <PlanEdit />,
  },
];

const routes = Routes.map((route) => (
  <Route
    key={route.path}
    path={route.path}
    caseSensitive={route.caseSensitive}
    element={route.component}
  />
));

export default routes;
