import { User } from '@auth0/auth0-react';
import { useGetCurrentUser } from 'apis/users';
import {
  Auth0DecodedHash,
  Auth0Error,
  Auth0ParseHashError,
  Auth0UserProfile,
} from 'auth0-js';
import { LoadingOverlay } from 'components/common';
import { getHashValue, getRedirectUri } from 'helpers';
import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from 'services/auth0.service';

export const AuthContext = createContext<{
  user?: User;
}>({
  user: undefined,
});

export const useAuthContext = () => useContext(AuthContext);

type AuthProviderProps = {
  children: ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const enableGetUser = window.location.pathname !== '/authenticate';
  const [appLoading, setAppLoading] = useState(true);
  const { data: user, isLoading, isError } = useGetCurrentUser(enableGetUser);

  const processHash = useCallback(() => {
    const { hash } = location;
    if (!hash) {
      navigate('/login');
    }

    const code = getHashValue(hash, 'code');
    auth.parseHash(
      { hash },
      (err: Auth0ParseHashError | null, result: Auth0DecodedHash | null) => {
        if (err) {
          toast.error(err?.errorDescription);
          navigate('/login');
          return;
        }
        if (result) {
          const { accessToken } = result;
          if (accessToken) {
            auth.client.userInfo(
              accessToken,
              async (err: Auth0Error | null, result: Auth0UserProfile) => {
                if (err) {
                  console.log(err);
                  navigate('/login');
                  return;
                }
                localStorage.setItem('accessToken', accessToken);
                if (code) {
                  auth.client.oauthToken(
                    {
                      code,
                      grantType: 'authorization_code',
                      redirectUri: getRedirectUri(),
                    },
                    (err, result) => {
                      if (result) {
                        const { refreshToken } = result;
                        localStorage.setItem('refreshToken', refreshToken);
                        navigate('/');
                      }
                    },
                  );
                }
              },
            );
          }
        }
      },
    );
  }, []);

  useEffect(() => {
    if (!enableGetUser) {
      processHash();
    }
  }, [enableGetUser]);

  useEffect(() => {
    if (user) {
      if (user?.app_metadata?.role !== 'rammp-admin') {
        console.log('this???', user);
        navigate('/login');
        setAppLoading(false);
      } else {
        if (window.location.pathname === '/login') {
          navigate('/');
        }
        setAppLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isError) {
      navigate('/login');
      setAppLoading(false);
    }
  }, [isError]);

  const loading = isLoading || appLoading;
  return (
    <AuthContext.Provider
      value={{
        user,
      }}
    >
      {loading ? <LoadingOverlay /> : children}
    </AuthContext.Provider>
  );
}
