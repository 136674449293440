import { Plan, useGetPlans } from 'apis/plans';
import { User, useUpdateUserSubscription } from 'apis/users';
import { Modal, ModalSize, SelectInput, TextInput } from 'components/common';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IBodySubscriptionUpdate } from 'types/users';

type EditSubscriptionModalProps = {
  user: User;
  open: boolean;
  handleClose: VoidFunction;
};

const EditSubscriptionModal = ({
  open,
  handleClose,
  user,
}: EditSubscriptionModalProps) => {
  const [stripeSubscriptionId, setStripeSubScriptionId] = useState('');
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [plan, setPlan] = useState<Plan | undefined>();
  const { data: plans, isLoading: isLoadingPlan } = useGetPlans();

  const { mutateAsync: updateSubscription, isLoading: isSubmitting } =
    useUpdateUserSubscription();
  useEffect(() => {
    if (user) {
      setPlan(user.subscription?.plan);
      setStripeCustomerId(user.subscription?.subscription?.stripe_customer_id);
      setStripeSubScriptionId(
        user.subscription?.subscription?.stripe_subscription_id,
      );
    }
  }, [user]);

  const handleSubmit = async () => {
    if (!plan || !stripeCustomerId || !stripeSubscriptionId) {
      toast.error('Please fill all the required fields');
    }

    const payload: IBodySubscriptionUpdate = {
      user_id: user.user_id,
      plan_id: plan.id,
      stripe_subscription_id: stripeSubscriptionId.trim(),
      stripe_customer_id: stripeCustomerId.trim(),
    };

    try {
      await updateSubscription(payload);
      handleClose();
      toast.success('Subscription updated');
    } catch (error) {
      toast.error('Error updating subscription. Please check with developer');
    }
  };
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title="Edit subscription"
      showHeader
      showFooter
      loading={isLoadingPlan}
      submitButtonTitle="Save"
      submitting={isSubmitting}
      handleSubmit={handleSubmit}
      size={ModalSize.sm}
    >
      <div className="p-6">
        <div className="text-secondary mb-4">
          First you select the plan which are available in our database.
          <br></br> Then when user makes payment via Stripe, that user will have
          Stripe subscription Id and customer Id, you need to copy and patse
          them here so that our system can keep track of the subscription
          <br></br>
          <br></br>So the idea is, Stripe processes the payment, we just store
          the subscription reference in our system and match with the right
          plan.
        </div>
        <div className="mb-4">
          <div className="mb-4 text-content-primary font-medium">
            Select plan:
          </div>
          <SelectInput
            value={plan}
            onChange={(value) => setPlan(value)}
            options={plans}
            renderSelectOption={(value: Plan) => value.name}
            renderSelectedValue={(value: Plan) => value.name}
            placeholder="Select plan..."
          />
        </div>
        <TextInput
          className="mb-4"
          value={stripeSubscriptionId}
          label="Stripe subscription id:"
          onChange={(e) => setStripeSubScriptionId(e.target.value)}
          placeholder="Enter stripe subscription id..."
        />
        <TextInput
          className="mb-4"
          value={stripeCustomerId}
          label="Stripe customer id:"
          onChange={(e) => setStripeCustomerId(e.target.value)}
          placeholder="Enter stripe customer id..."
        />
      </div>
    </Modal>
  );
};

export default EditSubscriptionModal;
