import React, { FunctionComponent, ReactNode, Fragment } from 'react';
import cn from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Dialog, Transition } from '@headlessui/react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { IconWrapper } from '../IconWrapper';
import { Button, ButtonVariant } from '../Button';
import { ModalSidebar, TabItem } from './ModalSidebar';
import { LoadingWrapper } from '../LoadingWrapper';

export const SIDEBAR_WIDTH = 216;

const MAX_CONTENT_HEIGHT = 'max-h-[calc(100vh-164px)]';
const CONTENT_FULL_SCREEN_HEIGHT = 'h-[calc(100%-166px)]';

export enum ModalPosition {
  top = 'top',
  center = 'center',
}

export enum ModalSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

const POSITION_STYLE_MAP = {
  [ModalPosition.top]: '',
  [ModalPosition.center]: 'min-h-full items-center',
};

const getSizeStyles = (variant: ModalSize) => {
  switch (variant) {
    case ModalSize.sm:
      return 'max-w-[448px]';
    case ModalSize.md:
      return 'max-w-[684px]';
    case ModalSize.lg:
      return 'max-w-[728px]';
    case ModalSize.xl:
      return 'max-w-[900px]';
    case ModalSize.xxl:
      return 'max-w-[1092px]';
    default:
      return 'max-w-[684px]';
  }
};

export interface ModalProps {
  size?: ModalSize;
  className?: string;
  open?: boolean;
  handleClose?: VoidFunction;
  handleSubmit?: VoidFunction;
  title?: string | ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  children?: ReactNode;
  enableSidebar?: boolean;
  activeTab?: string | number;
  tabs?: TabItem[];
  onTabChange?: (key: string | number) => void;
  position?: ModalPosition;
  fullScreen?: boolean;
  loading?: boolean;
  submitting?: boolean;
  disableSubmitButton?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  handleClose,
  handleSubmit,
  open,
  className = '',
  showHeader = false,
  showFooter = false,
  cancelButtonTitle = 'Cancel',
  submitButtonTitle = 'Submit',
  title = '',
  size = ModalSize.md,
  children,
  enableSidebar = false,
  tabs = [],
  activeTab = '',
  onTabChange,
  position = ModalPosition.center,
  fullScreen,
  loading,
  submitting,
  disableSubmitButton,
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={handleClose as any}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 dark:bg-[#9ca3af1a]" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div
            className={cn(
              fullScreen ? 'w-full h-full' : 'p-4',
              'flex justify-center text-center',
              POSITION_STYLE_MAP[position],
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cn(
                  fullScreen ? 'w-full h-full' : getSizeStyles(size),
                  'bg-surface-base border border-surface-div w-full overflow-hidden',
                  'transform  text-left align-middle shadow-xl transition-all',
                  fullScreen ? '' : 'rounded-lg',
                  className,
                )}
              >
                <div
                  className={cn(
                    fullScreen ? 'w-full' : '',
                    'flex flex-col justify-center items-center',
                  )}
                >
                  {showHeader && (
                    <div
                      className={cn(
                        fullScreen ? getSizeStyles(size) : '',
                        'w-full',
                        {
                          'px-4': fullScreen,
                        },
                      )}
                    >
                      <div
                        className={cn(
                          fullScreen ? 'pt-12 pb-6' : 'px-6 py-4',
                          'flex gap-2 w-full justify-between border-b border-surface-div items-center',
                        )}
                      >
                        <h2>{title}</h2>
                        <IconWrapper
                          icon={CloseIcon}
                          className={cn(
                            fullScreen ? 'absolute left-8 top-8 z-[1]' : '',
                            'cursor-pointer text-content-secondary',
                          )}
                          onClick={handleClose}
                        />
                      </div>
                    </div>
                  )}
                  {enableSidebar ? (
                    <div
                      className={cn(
                        'w-full flex justify-center',
                        fullScreen ? CONTENT_FULL_SCREEN_HEIGHT : '',
                      )}
                    >
                      <div
                        className={cn(
                          fullScreen ? getSizeStyles(size) : '',
                          'w-full flex h-full',
                        )}
                      >
                        <ModalSidebar
                          tabs={tabs}
                          activeTab={activeTab}
                          onTabChange={onTabChange}
                        />
                        <PerfectScrollbar
                          options={{
                            wheelPropagation: false,
                          }}
                          className={MAX_CONTENT_HEIGHT}
                          style={{
                            width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                          }}
                        >
                          <LoadingWrapper isLoading={!!loading}>
                            {children}
                          </LoadingWrapper>
                        </PerfectScrollbar>
                      </div>
                    </div>
                  ) : (
                    <PerfectScrollbar
                      options={{
                        wheelPropagation: false,
                      }}
                      className={cn('w-full', MAX_CONTENT_HEIGHT, {
                        'px-4': fullScreen,
                      })}
                    >
                      <div
                        className={cn(
                          fullScreen ? getSizeStyles(size) : '',
                          'mx-auto',
                        )}
                      >
                        <LoadingWrapper isLoading={!!loading}>
                          {children}
                        </LoadingWrapper>
                      </div>
                    </PerfectScrollbar>
                  )}
                </div>
                {showFooter && (
                  <div
                    className={cn(
                      fullScreen ? 'absolute left-0 bottom-0 w-full' : '',
                      'h-fit border-t border-surface-div flex justify-center px-6 py-4',
                    )}
                  >
                    <div
                      className={cn(
                        fullScreen ? getSizeStyles(size) : '',
                        'flex gap-2 w-full justify-end',
                      )}
                    >
                      <Button
                        variant={ButtonVariant.outline}
                        onClick={handleClose}
                        disabled={submitting}
                      >
                        {cancelButtonTitle}
                      </Button>
                      {handleSubmit && (
                        <Button
                          onClick={handleSubmit}
                          disabled={disableSubmitButton || submitting}
                        >
                          {submitButtonTitle}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
