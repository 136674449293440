import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { focusStyle } from 'styles/constants';

export type RadioProps = {
  className?: string;
  id: string;
  label?: string;
  helperText?: string;
  error?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Radio: FunctionComponent<RadioProps> = ({
  className = '',
  id,
  label = '',
  disabled = false,
  helperText = '',
  error,
  ...props
}) => {
  return (
    <label htmlFor={id} className={cn('relative flex items-center', className)}>
      <input
        className={cn(
          'min-w-[16px] min-h-[16px] border border-surface-div bg-surface-base',
          'rounded-full appearance-none checked:bg-accent-main',
          disabled ? 'checked:disabled:bg-content-tetriary' : 'cursor-pointer',
          focusStyle,
        )}
        id={id}
        disabled={disabled}
        type="radio"
        {...props}
      />
      <span
        className={cn(
          'w-1.5 h-1.5 absolute bg-surface-base left-2 translate-x-[-50%] rounded-full',
          disabled ? '' : 'cursor-pointer',
        )}
      />
      <span
        className={cn(
          'pl-2 font-medium select-none',
          disabled
            ? 'text-content-tetriary'
            : 'text-content-primary cursor-pointer',
        )}
      >
        {label}
      </span>
      {helperText && (
        <div
          className={cn(
            'absolute text-sm cursor-text left-0 top-[calc(100%+2px)]',
            error ? 'text-error-main' : 'text-content-secondary',
          )}
        >
          {helperText}
        </div>
      )}
    </label>
  );
};
