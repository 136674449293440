import React, { FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import { Portal } from 'react-portal';
import { Loader, LoaderSize } from '../Loader';

export interface LoadingOverlayProps {
  className?: string;
  title?: string | ReactNode;
}

export const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({
  className = '',
  title = 'Loading...',
  ...props
}) => {
  return (
    <Portal>
      <div
        className={cn(
          'fixed top-0 left-0 w-full h-full z-[1000] flex justify-center items-center bg-surface-base',
          className,
        )}
        {...props}
      >
        <div className="flex flex-col items-center">
          <Loader className="mb-4" size={LoaderSize.xxl} />
          <div className="text-content-secondary">{title}</div>
        </div>
      </div>
    </Portal>
  );
};
