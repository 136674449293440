import { Widget, useGetWidgets } from 'apis/widgets';
import { Column, Pagination, Row, Table } from 'components/common';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LIMIT = 50;
const Widgets = () => {
  const [skip, setSkip] = useState(0);

  const { data, isLoading } = useGetWidgets();

  const navigate = useNavigate();
  const columns: Column[] = [
    {
      id: 'user',
      label: 'Owner',
      customCellRender(value, row) {
        return value?.email;
      },
      className: 'w-64',
    },
    {
      id: 'domains',
      label: 'Domains Whitelisted',
      customCellRender(value, row) {
        return value?.map((domain) => domain.url).join(', ');
      },
      className: 'w-[450px]',
    },
    {
      id: 'reports',
      label: 'Total reports generated',
      customCellRender(value, row) {
        return value?.length || 0;
      },
      className: 'w-[200px]',
    },
    {
      id: 'status',
      label: 'Status',
      customCellRender(value, row: Widget) {
        const isFeatureEnabled = row?.user?.app_metadata?.enableLeadGenTool;
        const isUserEnterprise =
          row?.user?.subscription?.plan?.plan_type?.includes('enterprise');
        const isEnabled = isFeatureEnabled || isUserEnterprise;
        return isEnabled ? 'enabled' : 'disabled';
      },
    },
  ];

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => a.id.localeCompare(b.id));
  }, [data]);

  const brands = useMemo(() => {
    return sortedData?.slice(skip, skip + LIMIT) || [];
  }, [sortedData, skip]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">Lead Gen Widgets Management</h1>
      <div>
        <Table
          className="mt-5 w-full overflow-y-auto"
          classNameTable="w-full"
          classNameThead="sticky top-0 bg-surface-base text-left"
          columns={columns}
          rows={brands}
          loading={isLoading}
          onRowClick={(row: Row) => navigate(`/widgets/${row.id}`)}
        />
        <Pagination
          className="mt-5"
          total={sortedData?.length || 0}
          skip={skip}
          limit={LIMIT}
          handlePageChange={(page) => {
            setSkip(page * LIMIT);
          }}
        />
      </div>
    </div>
  );
};

export default Widgets;
