import React from 'react';
import Sidebar from './Sidebar/Sidebar';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

const AppLayout = (props) => {
  const { children } = props;
  const { pathname } = useLocation();

  const showSidebar = pathname !== '/login';
  return (
    <div className={cn('relative', showSidebar ? 'pl-sidebar' : '')}>
      {showSidebar && <Sidebar />}
      {children}
    </div>
  );
};

export default AppLayout;
