import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonVariant,
  ConfirmDialog,
  IconButton,
  IconButtonVariant,
  IconSize,
  Link,
  Pagination,
  Row,
  Table,
} from 'components/common';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-alt.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Role, User, useDeleteUserById, useGetAllUsers } from 'apis/users';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';

const LIMIT = 1000;
const UserManagement = () => {
  const [skip, setSkip] = useState(0);
  const [showModal, setShowModal] = useState<{
    isShow?: boolean;
    user_id?: string;
    fullName?: string;
    email: string | undefined;
  }>(null);

  const [filter, setFilter] = useState<"other" | "starter" | "rammp-ai" | "team">();
  const { data, isLoading, isFetching } = useGetAllUsers();
  const { mutateAsync, isLoading: isLoadingDelete } = useDeleteUserById();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const columns = [
    {
      id: 'user_id',
      label: 'UserId',
      className: ' w-60',
    },
    {
      id: 'name',
      label: 'Name',
      className: ' w-56',
    },
    {
      id: 'email',
      label: 'Email',
    },
    {
      id: 'totalBrands',
      label: 'Total brands',
    },
    {
      id: 'subscription',
      label: 'Subscription Plan',
    },
    {
      id: 'report_credit',
      label: 'Report credits',
    },
    {
      id: 'email_verified',
      label: 'Email Verified',
    },
    {
      id: 'role',
      label: 'Role',
    },
    {
      id: 'created_at',
      label: 'Created At',
      customCellRender: (value) => moment(value).format('YYYY-MM-DD'),
    },
    {
      id: 'edit',
      label: '',
      customCellRender: (_, row) => (
        <Link
          asRouterLink
          routerLinkProps={{
            to: `/users/${row.user_id}`,
          }}
        >
          <IconButton
            icon={EditIcon}
            iconSize={IconSize.lg}
            variant={IconButtonVariant.text}
          />
        </Link>
      ),
    },
    {
      id: 'delete',
      label: '',
      customCellRender: (_, row) => {
        if (row.user_id === user.user_id) {
          return null;
        }
        return (
          <IconButton
            icon={TrashIcon}
            iconSize={IconSize.lg}
            variant={IconButtonVariant.text}
            onClick={(e) => {
              e.stopPropagation();
              setShowModal({
                isShow: true,
                user_id: row.user_id,
                fullName: row.name,
                email: row.email,
              });
            }}
          />
        );
      },
    },
  ];

  const handleDeleteUser = async () => {
    if (showModal?.user_id) {
      await mutateAsync(showModal?.user_id);
      setShowModal(null);
    }
  };

  const formattedData = useMemo(() => {
    let result = data?.data || [];

    if (filter === "starter") {
      result = result?.filter((u) => u?.subscription?.plan?.plan_type === "starter");
    } else if (filter === "rammp-ai") {
      result = result?.filter((u) => u?.subscription?.plan?.plan_type === "basic");
    } else if (filter === "team") {
      result = result?.filter((u) => u?.subscription?.plan?.plan_type === "pro");
    } else if (filter === "other") {
      result = result?.filter((u) => !(["starter", "basic", "pro"].includes(u?.subscription?.plan?.plan_type)));
    }

    return result
      ?.map((user: User) => {
        return {
          id: user.user_id,
          user_id: user.user_id,
          email: user.email,
          name: user.user_metadata?.fullName,
          created_at: user.created_at,
          updated_at: user.updated_at,
          role:
            user.app_metadata?.role === Role.RAMMP_ADMIN
              ? 'Rammpian Admin'
              : 'User',
          email_verified: user.email_verified ? 'Verified' : 'Not Verified',
          totalBrands: user.brands.length,
          report_credit: user.app_metadata?.report_credit || 0,
          subscription: user?.subscription?.plan?.name || '',
        };
      })
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
  }, [data, filter]);

  const paginatedRows = useMemo(() => {
    return formattedData?.slice(skip, skip + LIMIT) || [];
  }, [formattedData, skip]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">User Management</h1>
      <div>
        <div className="flex gap-2">
          <Button
            variant={ButtonVariant.outline}
            onClick={() => navigate('/users/create')}
            prefixIcon={PlusIcon}
          >
            Create User
          </Button>

          <Button
            variant={
              filter === undefined ? ButtonVariant.primary : ButtonVariant.outline
            }
            onClick={() => {
              setSkip(0);
              setFilter(undefined)
            }}
          >
            Show all
          </Button>

          <Button
            variant={
              filter === "starter" ? ButtonVariant.primary : ButtonVariant.outline
            }
            onClick={() => {
              setSkip(0);
              setFilter("starter")
            }}
          >
            Show starter
          </Button>

          <Button
            variant={
              filter === "rammp-ai" ? ButtonVariant.primary : ButtonVariant.outline
            }
            onClick={() => {
              setSkip(0);
              setFilter("rammp-ai")
            }}
          >
            Show RAMMP.AI
          </Button>

          <Button
            variant={
              filter === "team" ? ButtonVariant.primary : ButtonVariant.outline
            }
            onClick={() => {
              setSkip(0);
              setFilter("team")
            }}
          >
            Show team
          </Button>

          <Button
            variant={
              filter === "other" ? ButtonVariant.primary : ButtonVariant.outline
            }
            onClick={() => {
              setSkip(0);
              setFilter("other")
            }}
          >
            Show others
          </Button>
        </div>

        <div>
          <Table
            className="mt-5 w-full overflow-y-auto"
            classNameTable="w-full"
            classNameThead="sticky top-0 bg-surface-base text-left"
            columns={columns}
            rows={paginatedRows}
            loading={isLoading || isFetching}
            onRowClick={(row: Row) => navigate(`/users/${row.user_id}`)}
          />
          <Pagination
            className="mt-5"
            total={formattedData?.length || 0}
            skip={skip}
            limit={LIMIT}
            handlePageChange={(page) => {
              setSkip(page * LIMIT);
            }}
          />
        </div>
      </div>
      {showModal && (
        <ConfirmDialog
          open={showModal.isShow}
          handleClose={() => setShowModal(null)}
          handleSubmit={handleDeleteUser}
          submitButtonProps={{
            isLoading: isLoadingDelete,
          }}
          submitButtonTitle='Delete'
        >
          <p>
            Do you want to delete this user: {showModal.email || "NO EMAIL"}? This action
            may or may not be able be undone. What do you think?
          </p>
        </ConfirmDialog>
      )}
    </div>
  );
};

export default UserManagement;
