import React, {
  FunctionComponent,
  InputHTMLAttributes,
  useState,
  useCallback,
  useEffect,
  RefObject,
} from 'react';
import cn from 'classnames';
import { TextInput } from '../TextInput';
import { debounce } from 'lodash';
import { IconWrapper } from '../IconWrapper';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export type SearchInputProps = {
  className?: string;
  value?: string;
  handleSearch?: (value: string) => void;
  placeholder?: string;
  hideClear?: boolean;
  helperText?: string;
  error?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  hidePrefixIcon?: boolean;
  reformatSearchValue?: (value: string) => string;
} & InputHTMLAttributes<HTMLInputElement>;

export const SearchInput: FunctionComponent<SearchInputProps> = ({
  className = '',
  value,
  handleSearch,
  placeholder,
  hideClear = false,
  helperText = '',
  error,
  inputRef,
  hidePrefixIcon,
  reformatSearchValue = (value: string) => value,
  ...props
}) => {
  const [search, setSearch] = useState('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(reformatSearchValue(e.target.value));
  };

  useEffect(() => {
    if (value !== undefined) {
      setSearch(value);
    }
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce(async (val: string) => {
      if (handleSearch) handleSearch(val);
    }, 500),
    [],
  );

  useEffect(() => {
    onSearch(search);
  }, [search, onSearch]);

  return (
    <div className={cn('w-full relative', className)}>
      <TextInput
        inputClassName={cn('peer', !hidePrefixIcon ? 'pl-10' : '')}
        placeholder={placeholder}
        value={search}
        onChange={handleSearchChange}
        helperText={helperText}
        error={error}
        inputRef={inputRef}
        {...props}
      />
      {!hidePrefixIcon && (
        <IconWrapper
          icon={SearchIcon}
          className={cn(
            'peer-focus:text-accent-main text-content-tetriary dark:text-content-tetriary-reverse',
            'absolute left-4 top-[50%] translate-y-[-50%]',
          )}
        />
      )}

      {search && !hideClear && (
        <IconWrapper
          icon={CloseIcon}
          onClick={() => setSearch('')}
          className={cn(
            'text-content-tetriary cursor-pointer dark:text-content-tetriary-reverse',
            'absolute right-4 top-[50%] translate-y-[-50%]',
          )}
        />
      )}
    </div>
  );
};
