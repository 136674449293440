import React, { FunctionComponent } from 'react';
import { TextInput } from '../TextInput';
import { Button, ButtonVariant } from '../Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import { IconWrapper } from '../IconWrapper';
import { IconButton, IconButtonSize, IconButtonVariant } from '../IconButton';

export type StringArrayInputProps = {
  className?: string;
  values?: string[];
  onChange?: (values: string[]) => void;
};

export const StringArrayInput: FunctionComponent<StringArrayInputProps> = ({
  className = '',
  values = [],
  onChange,
  ...props
}) => {
  const handleValueChange = (value: string, index: number) => {
    const newList = [...values];
    newList[index] = value;
    onChange?.(newList);
  };

  const handleAddNewValue = () => {
    onChange?.([...values, '']);
  };

  const handleDeleteValue = (index: number) => {
    const newList = [...values];
    newList.splice(index, 1);
    onChange?.(newList);
  };

  return (
    <div>
      {values.map((item, index) => (
        <div key={index} className="flex gap-2 mb-2">
          <TextInput
            value={item}
            className="flex-1"
            onChange={(e) => handleValueChange(e.target.value, index)}
          />
          <IconButton
            onClick={() => handleDeleteValue(index)}
            variant={IconButtonVariant.outline}
            icon={TrashIcon}
            size={IconButtonSize.xl}
            className="text-error-main h-[34px] w-[34px]"
          />
        </div>
      ))}
      <Button onClick={handleAddNewValue} variant={ButtonVariant.outline}>
        <IconWrapper icon={PlusIcon} />
      </Button>
    </div>
  );
};
