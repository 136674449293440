import React, {
  FunctionComponent,
  ReactNode,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import cn from 'classnames';
import { Portal } from 'react-portal';
import { IconWrapper } from '../IconWrapper';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { SearchInput, SearchInputProps } from '../SearchInput';
import { isEqual } from 'lodash';
import { useClickOutside, usePortalPosition } from 'hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';

const INPUT_MIN_HEIGHT = 34;

type ValueType =
  | number
  | string
  | { id?: string | number; disabled?: boolean; [key: string]: any };

export enum SelectVariant {
  regular = 'regular',
  inline = 'inline',
  custom = 'custom',
}

export type SelectInputProps<T extends ValueType> = {
  className?: string;
  value?: T;
  options?: T[];
  placeholder?: string | ReactNode;
  onChange?: (val?: T) => void;
  renderSelectedValue?: (val: T) => ReactNode;
  renderSelectOption?: (val: T, selected?: boolean) => ReactNode;
  renderCustomSelectButton?: (
    value: T,
    handleToggle: VoidFunction,
    clearable: boolean,
  ) => ReactNode;
  searchable?: boolean;
  clearable?: boolean;
  variant?: SelectVariant;
  handleSearch?: (value: string) => void;
  menuWidth?: number;
  menuMaxWidth?: number;
  menuMinWidth?: number;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  onToggle?: (openMenu: boolean) => void;
  resetSearchOnSelectValue?: boolean;
  searchInputProps?: SearchInputProps;
  noOptionsText?: string;
  usePortalMenu?: boolean;
  menuClassName?: string;
};

export const SelectInput = <T extends ValueType>({
  className,
  options = [],
  value,
  onChange,
  handleSearch,
  renderSelectedValue = (val) => String(val),
  renderSelectOption = (val) => String(val),
  renderCustomSelectButton,
  menuWidth,
  menuMaxWidth,
  menuMinWidth,
  placeholder = '',
  searchable = false,
  clearable = false,
  variant = SelectVariant.regular,
  disabled = false,
  helperText = '',
  error,
  searchInputProps,
  onToggle,
  resetSearchOnSelectValue = true,
  noOptionsText,
  usePortalMenu = true,
  menuClassName,
}: SelectInputProps<T>): ReturnType<FunctionComponent> => {
  const [openMenu, setOpenMenu] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const searchInputref = useRef<HTMLInputElement>(null);
  const { position } = usePortalPosition({
    openMenu,
    wrapperRef,
    popoverRef,
    isFixedMenuWidth: !!menuWidth,
  });

  useClickOutside({
    wrapperRef,
    popoverRef,
    openMenu,
    onClickOutside: () => {
      if (openMenu) {
        setOpenMenu(false);
        onToggle?.(false);
      }
    },
  });

  const handleOptionClick = (option: T, currentlySelected: boolean) => {
    if (clearable && currentlySelected) {
      onChange?.(undefined);
    } else {
      onChange?.(option);
    }
    setOpenMenu(false);
    onToggle?.(false);

    window.requestAnimationFrame(() => {
      if (resetSearchOnSelectValue) {
        handleSearch?.('');
      }
    });
  };

  const handleClearValue = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    handleSearch?.('');
    onChange?.(undefined as any);
  };

  const handleToggle = useCallback(() => {
    if (disabled) {
      return;
    }
    if (searchable) {
      setOpenMenu(true);
      onToggle?.(true);
    } else {
      setOpenMenu((prev) => {
        onToggle?.(!prev);
        return !prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchable, disabled]);

  useEffect(() => {
    if (openMenu && searchInputref.current) {
      setTimeout(() => {
        searchInputref.current?.focus();
      }, 50);
    }
  }, [openMenu]);

  const renderSelectButton = () => {
    if (variant === SelectVariant.custom) {
      return renderCustomSelectButton?.(value as T, handleToggle, clearable);
    }
    if (variant === SelectVariant.inline) {
      return (
        <div className="w-fit rounded-md" onClick={handleToggle}>
          <div
            className={cn(
              'relative rounded-md hover:bg-action-hover',
              'flex items-center font-medium text-content-primary gap-3 px-2 py-1',
              disabled ? 'cursor-default' : 'cursor-pointer',
              openMenu ? 'bg-action-hover' : '',
            )}
          >
            {value ? (
              renderSelectedValue(value)
            ) : (
              <span className="font-normal text-content-secondary">
                {placeholder}
              </span>
            )}
            {clearable && value ? (
              <IconWrapper
                icon={CloseIcon}
                onClick={handleClearValue}
                className="text-content-secondary hover:text-content-primary"
              />
            ) : (
              <IconWrapper
                icon={ChevronDown}
                className="pointer-events-none text-content-secondary"
              />
            )}
            {helperText && (
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                  e.stopPropagation()
                }
                className={cn(
                  'absolute text-sm cursor-text left-0 top-[calc(100%+2px)]',
                  error ? 'text-error-main' : 'text-content-secondary',
                )}
              >
                {helperText}
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="w-full rounded-md shadow-sm" onClick={handleToggle}>
        <div
          style={{ minHeight: `${INPUT_MIN_HEIGHT}px` }}
          className={cn(
            'relative w-full',
            !(searchable && openMenu)
              ? cn(
                  ' flex items-center px-3 border',
                  'text-content-primary rounded-md bg-surface-overlay cursor-pointer',
                  openMenu ? 'ring-action-focused ring-2' : '',
                  error
                    ? 'border-error-main !ring-error-main !ring-1 '
                    : 'border-surface-div',
                )
              : '',
          )}
        >
          {searchable ? (
            openMenu ? (
              <SearchInput
                inputRef={searchInputref}
                className="w-full"
                hidePrefixIcon
                handleSearch={handleSearch}
                placeholder={String(placeholder)}
                hideClear
                {...searchInputProps}
              />
            ) : value ? (
              renderSelectedValue(value)
            ) : (
              <span className="text-content-secondary">{placeholder}</span>
            )
          ) : value ? (
            renderSelectedValue(value)
          ) : (
            <span className="text-content-secondary">{placeholder}</span>
          )}
          {/* {value ? (
            renderSelectedValue(value)
          ) : (
            <>
              {searchable ? (
                <SearchInput
                  inputRef={searchInputref}
                  className="w-full"
                  hidePrefixIcon
                  handleSearch={handleSearch}
                  placeholder={String(placeholder)}
                  hideClear
                  {...searchInputProps}
                />
              ) : (
                <span className="text-content-secondary">{placeholder}</span>
              )}
            </>
          )} */}
          {clearable && value ? (
            <IconWrapper
              icon={CloseIcon}
              onClick={handleClearValue}
              className="absolute text-content-secondary right-3 top-[50%] translate-y-[-50%] hover:text-content-primary"
            />
          ) : (
            <IconWrapper
              icon={ChevronDown}
              className="pointer-events-none absolute text-content-secondary right-3 top-[50%] translate-y-[-50%]"
            />
          )}
          {helperText && (
            <div
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                e.stopPropagation()
              }
              className={cn(
                'absolute text-sm cursor-text left-0 top-[calc(100%+2px)]',
                error ? 'text-error-main' : 'text-content-secondary',
              )}
            >
              {helperText}
            </div>
          )}
        </div>
      </div>
    );
  };

  const MenuWrapper = usePortalMenu ? Portal : React.Fragment;
  return (
    <div className={cn(className, 'relative')} ref={wrapperRef}>
      {renderSelectButton()}
      {openMenu && (
        <MenuWrapper>
          <div
            ref={popoverRef}
            style={{
              visibility: position?.left ? 'visible' : 'hidden',
              width: menuWidth || position?.width,
              left: position?.left || 0,
              top: position?.top || 0,
              ...(menuMaxWidth ? { maxWidth: `${menuMaxWidth}px` } : {}),
              ...(menuMinWidth ? { minWidth: `${menuMinWidth}px` } : {}),
            }}
            className={cn(
              menuClassName,
              'absolute py-1 w-full rounded-md z-40',
              'bg-surface-overlay shadow-md border border-surface-div text-content-primary',
            )}
          >
            {searchable && variant !== SelectVariant.regular && (
              <SearchInput
                inputRef={searchInputref}
                handleSearch={handleSearch}
                placeholder={String(placeholder)}
                hidePrefixIcon
                className="mb-2 px-2 mt-1"
                {...searchInputProps}
              />
            )}
            <PerfectScrollbar
              options={{
                wheelPropagation: false,
              }}
              className="max-h-52"
            >
              {options.length ? (
                options.map((option, idx) => {
                  const selected =
                    isEqual(option, value) ||
                    (typeof option === 'object' &&
                      typeof value === 'object' &&
                      option?.id === value?.id);
                  return (
                    <div
                      key={
                        typeof option === 'object'
                          ? option?.id || option?.value
                          : idx
                      }
                      style={{ minHeight: `${INPUT_MIN_HEIGHT}px` }}
                      className={cn(
                        'relative select-none flex items-center px-3',
                        {
                          'hover:bg-action-hover cursor-pointer':
                            typeof option === 'object'
                              ? !option?.disabled
                              : true,

                          'text-content-tetriary':
                            typeof option === 'object' && option?.disabled,
                          'font-bold': selected,
                        },
                      )}
                      onClick={() =>
                        (typeof option === 'object'
                          ? !option?.disabled
                          : true) && handleOptionClick(option, selected)
                      }
                    >
                      {renderSelectOption(option, selected)}
                    </div>
                  );
                })
              ) : (
                <div
                  style={{ minHeight: `${INPUT_MIN_HEIGHT}px` }}
                  className={cn('relative select-none flex items-center px-3')}
                >
                  {noOptionsText}
                </div>
              )}
            </PerfectScrollbar>
          </div>
        </MenuWrapper>
      )}
    </div>
  );
};
