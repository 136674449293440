import React, { FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import { Loader, LoaderSize } from '../Loader';

export type Column = {
  id: string;
  label: string | ReactNode;
  customCellRender?: (value: any, row: Row) => ReactNode;
  className?: string;
};

export type Row = {
  id: string | number;
  [key: string]: any;
};

export type TableProps = {
  className?: string;
  classNameTable?: string;
  classNameThead?: string;
  columns?: Column[];
  rows?: Row[];
  loading?: boolean;
  onRowClick?: (row: Row) => void;
};

export const Table: FunctionComponent<TableProps> = ({
  className = '',
  classNameTable = '',
  classNameThead = '',
  rows,
  columns,
  loading,
  onRowClick,
}) => {
  return (
    <div className={className}>
      <table className={classNameTable}>
        <thead className={classNameThead}>
          <tr>
            {columns.map((column) => {
              return (
                <th
                  className={cn(
                    'text-content-secondary font-medium py-2 px-2 select-none',
                    column.className,
                  )}
                  key={column.id}
                >
                  {column.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={columns.length}>
                <div className="w-full h-80 flex items-center justify-center">
                  <Loader size={LoaderSize.xxl} />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {rows.map((row) => {
                const tdElements = columns.map((column, index) => {
                  const value = row?.[column?.id];
                  return (
                    <td
                      key={`${column.id}-${row.id}`}
                      className={cn(
                        'text-content-primary font-medium py-3 px-2',
                      )}
                    >
                      {column.customCellRender
                        ? column.customCellRender(value, row)
                        : value}
                    </td>
                  );
                });

                return (
                  <tr
                    key={row.id}
                    className={cn(
                      'border-b border-surface-div',
                      onRowClick ? 'hover:bg-action-hover cursor-pointer' : '',
                    )}
                    onClick={() => onRowClick(row)}
                  >
                    {tdElements}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
