import React, {
  FunctionComponent,
  InputHTMLAttributes,
  RefObject,
} from 'react';
import cn from 'classnames';
import { focusStyle } from 'styles/constants';

export type TextInputProps = {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  helperTextClassName?: string;
  label?: string;
  helperText?: string;
  error?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextInput: FunctionComponent<TextInputProps> = ({
  className = '',
  inputClassName = '',
  labelClassName = '',
  label = '',
  helperText = '',
  helperTextClassName = '',
  error,
  inputRef,
  disabled,
  ...props
}) => {
  return (
    <div className={cn('relative', className)}>
      {label && (
        <p
          className={cn(
            'mb-4 text-content-primary font-medium',
            labelClassName,
          )}
        >
          {label}
        </p>
      )}
      <input
        className={cn(
          'w-full bg-surface-overlay text-content-primary',
          'rounded-md border  min-h-[34px] px-3 shadow-sm',
          focusStyle,
          error
            ? 'border-error-main !ring-error-main !ring-1 '
            : 'border-surface-div',
          '!ring-offset-0',
          disabled ? '!text-content-tetriary cursor-not-allowed' : '',
          inputClassName,
        )}
        disabled={disabled}
        ref={inputRef}
        {...props}
      />
      {helperText && (
        <div
          className={cn(
            'absolute text-sm left-0 top-[calc(100%+2px)]',
            error ? 'text-error-main' : 'text-content-secondary',
            helperTextClassName,
          )}
        >
          {helperText}
        </div>
      )}
    </div>
  );
};
