import { useMutation, useQuery, useQueryClient } from 'react-query';
import baseApi from './base';
import { User } from './users';

export type Score = {
  rammpScore: number;
  f0: number;
  f10Sec: number;
  f3Min: number;
  fSignUp: number;
  f48Hours: number;
  fBuy: number;
};

export type Report = {
  id: string;
  brand_id?: string;
  isAnalyticsGenerated?: boolean;
  metrics: {
    visitors?: number;
    timeOnSite?: string;
    returnVisitors?: number;
    newUsers?: number;
    revenue?: number;
    buyers?: number;
    averageSignups48?: number;
    lastMilestoneUnlocked?: boolean;
    averageBuy?: number;
    pagesVisited?: number;
    bounceRate?: number;
    averageSignups?: number;
  };
  isUnlocked?: boolean; // There is a restriction on the free user. This field will unlock that restriction
  created_at: string;
  scores?: Score;

  widget_id?: string;
  widgetDataCollect?: {
    brandUrl?: string;
    email?: string;
    ip?: string;
    country?: string;
    domain?: string;
  };
};

export type Brand = {
  id: string;
  owner_id: string;
  owner?: User;
  url: string;
  is_archived: boolean;
  reports?: Report[];
};

export const GET_BRANDS_KEY = 'GET/brands';
export const useGetBrands = () => {
  return useQuery([GET_BRANDS_KEY], async () => {
    const { data } = await baseApi.get(`/admin/brands`);
    return data?.data as Brand[];
  });
};

export const GET_BRAND_BY_ID_KEY = 'GET/brand';
export const useGetBrandById = (id: string) => {
  return useQuery([GET_BRAND_BY_ID_KEY, id], async () => {
    const { data } = await baseApi.get(`/admin/brands/${id}`);
    return data?.data as Brand;
  });
};

export const UPDATE_BRAND_BY_ID_KEY = 'UPDATE/brand';
export const useUpdateBrandById = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    [UPDATE_BRAND_BY_ID_KEY],
    async (payload: Partial<Brand>) => {
      const { data } = await baseApi.put(`/admin/brands/${id}`, payload);
      return data as Brand;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_BRANDS_KEY]);
        queryClient.invalidateQueries([GET_BRAND_BY_ID_KEY]);
      },
    },
  );
};

export const DELETE_BRAND_BY_ID_KEY = 'DELETE/brand';
export const useDeleteBrandById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [DELETE_BRAND_BY_ID_KEY],
    async (id: string) => {
      const { data } = await baseApi.delete(`/admin/brands/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_BRANDS_KEY]);
      },
    },
  );
};
