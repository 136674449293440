import { FunctionComponent } from 'react';
import { Loader, LoaderSize } from '../Loader';
import cn from 'classnames';

export interface LoadingWrapperProps {
  isLoading: boolean;
  isError?: boolean;
  children: React.ReactNode;
  isZeroState?: boolean;
}

export const LoadingWrapper: FunctionComponent<LoadingWrapperProps> = ({
  isLoading,
  isError,
  children,
  isZeroState,
}) => {
  return (
    <div
      className={cn(
        'flex flex-col',
        isLoading || isError || isZeroState
          ? 'h-[300px]'
          : 'h-full',
      )}
    >
      {!isError && isLoading && (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader size={LoaderSize.xxl} />
        </div>
      )}
      {isError && (
        <div className="flex flex-col items-center justify-center h-full">
          <div className="text-content-secondary">Something went wrong</div>
        </div>
      )}
      {!isLoading && !isError && children}
    </div>
  );
};
