import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import ReactPaginate from 'react-paginate';
import { IconSize, IconWrapper } from '../IconWrapper';
import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';

export type PaginationProps = {
  className?: string;
  handlePageChange: (page: number) => void;
  total: number;
  limit?: number;
  skip: number;
  pageRangeDisplayed?: number;
};

export const Pagination: FunctionComponent<PaginationProps> = ({
  className = '',
  handlePageChange,
  total,
  limit = 10,
  pageRangeDisplayed = 3,
  skip,
  ...props
}) => {
  if (limit < 1) {
    console.error('Pagination limit cannot be lower than 1');
    return null;
  }

  if (total <= limit) {
    return null;
  }
  return (
    <div
      className={cn(className, 'flex justify-between items-center')}
      {...props}
    >
      <div className="text-content-secondary font-medium">
        Showing{' '}
        <span className="text-content-primary">
          {skip + 1}-{Math.min(skip + limit, total)}
        </span>{' '}
        of <span className="text-content-primary">{total}</span>
      </div>
      <ReactPaginate
        previousLabel={<IconWrapper icon={LeftArrow} size={IconSize.sm} />}
        nextLabel={<IconWrapper icon={RightArrow} size={IconSize.sm} />}
        breakLabel={'...'}
        breakLinkClassName={
          'text-content-tetriary hover:text-content-secondary'
        }
        pageCount={Math.ceil(total / limit)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={pageRangeDisplayed}
        onPageChange={(event) => handlePageChange(event.selected)}
        containerClassName={'flex gap-4 items-center font-medium select-none'}
        activeLinkClassName={'cursor-pointer !text-content-primary'}
        disabledLinkClassName={'cursor-default !text-content-tetriary'}
        nextLinkClassName={'text-content-tetriary hover:text-content-secondary'}
        previousLinkClassName={
          'text-content-tetriary hover:text-content-secondary'
        }
        pageLinkClassName={'text-content-tetriary hover:text-content-secondary'}
      />
    </div>
  );
};
