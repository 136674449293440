import { Role } from 'apis/users';

export interface InitUser {
  email?: string;
  name: string;
  enableLogoCustomize: boolean;
  enableLeadGenTool: boolean;
  role: RoleUser;
  password?: string;
  reportCredits: number;
}

export interface IBodyUserCreate {
  email: string;
  user_metadata: {
    fullName: string;
  };
  app_metadata: {
    enableLogoCustomize: boolean;
    role?: Role;
    report_credit?: number;
    enableLeadGenTool: boolean;
  };
  password: string;
  connection: string;
}

export interface IBodyUserUpdate {
  user_metadata: {
    fullName: string;
  };
  app_metadata: {
    enableLogoCustomize: boolean;
    role?: Role;
    report_credit?: number;
    enableLeadGenTool: boolean;
  };
  password?: string;
}

export interface IBodySubscriptionUpdate {
  user_id: string;
  plan_id: string;
  stripe_subscription_id: string;
  stripe_customer_id: string;
}

export enum RoleUser {
  ADMIN = 'Rammpian Admin',
  USER = 'User',
}
