import React, { FunctionComponent } from 'react';
import cn from 'classnames';

export enum ToggleSize {
  md = 'md',
  lg = 'lg',
}

const getSizeStyles = (variant: ToggleSize) => {
  switch (variant) {
    case ToggleSize.md:
      return 'w-11 h-6 after:h-5 after:w-5 after:top-0.5 after:left-[2px] peer-checked:after:translate-x-full';
    case ToggleSize.lg:
      return 'w-14 h-7 after:h-[22px] after:w-[22px] after:top-[3px] after:left-[3px] peer-checked:after:translate-x-[28px]';
    default:
      return 'w-11 h-6 after:h-5 after:w-5';
  }
};

export interface ToggleProps {
  className?: string;
  checked?: boolean;
  size?: ToggleSize;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const Toggle: FunctionComponent<ToggleProps> = ({
  className = '',
  size = ToggleSize.md,
  checked = false,
  onChange,
  disabled = false,
  ...props
}) => {
  return (
    <label
      className={cn(
        'relative flex items-center h-fit',
        disabled ? 'cursor-default' : 'cursor-pointer',
        className,
      )}
      {...props}
    >
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <div
        className={cn(
          getSizeStyles(size),
          'rounded-full border border-surface-div bg-surface-alt peer-checked:bg-accent-main',
          'peer peer-focus:ring-1 peer-focus:ring-accent-main',
          "after:content-[''] after:absolute after:bg-surface-overlay dark:after:bg-surface-base",
          'after:rounded-full after:transition-all after:shadow-base dark:peer-checked:after:bg-surface-overlay-reverse',
        )}
      ></div>
    </label>
  );
};
