import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonVariant,
  IconSize,
  IconWrapper,
  Radio,
  SelectInput,
  TextInput,
} from 'components/common';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { InitUser, RoleUser } from 'types/users';
import { Role, useCreateUser } from 'apis/users';
import { toastError, toastSuccess } from 'helpers/toast';
import { IResponseError } from 'types';

const initUserData = {
  email: '',
  name: '',
  enableLogoCustomize: false,
  enableLeadGenTool: false,
  role: RoleUser.USER,
  password: '',
  reportCredits: 0,
};

const UserCreate = () => {
  const [user, setUser] = useState<InitUser>(initUserData);
  const { mutate, isSuccess, isLoading, isError, error } = useCreateUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Created successfully');
      navigate('/users');
    }
    if (isError && error) {
      const err = error as IResponseError;
      toastError(`${err?.response.data.message}`);
    }
  }, [isSuccess, isError, navigate]);

  const onChangeValue = (
    key: string,
    value: boolean | RoleUser | string | number,
  ) => {
    setUser({
      ...user,
      [key]: value,
    });
  };

  const onSubmit = async () => {
    mutate({
      email: user.email,
      user_metadata: {
        fullName: user.name,
      },
      app_metadata: {
        enableLogoCustomize: user.enableLogoCustomize || false,
        role: user.role === RoleUser.ADMIN ? Role.RAMMP_ADMIN : Role.USER,
        report_credit: user?.reportCredits || 0,
        enableLeadGenTool: user.enableLeadGenTool || false,
      },
      password: user.password,
      connection: 'Username-Password-Authentication',
    });
  };

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">Create a new user</h1>
      <Button variant={ButtonVariant.text} onClick={() => navigate(-1)}>
        <IconWrapper
          icon={ChevronLeftIcon}
          size={IconSize.sm}
          className="mr-1"
        />
        Back
      </Button>
      <div className="flex flex-col items-center justify-center py-8">
        <div className="w-96 bg-white rounded-lg shadow ">
          <div className="p-6 space-y-4">
            <div className="space-y-4">
              <TextInput
                id="email"
                label="Email"
                value={user?.email}
                onChange={(e) => {
                  const { target } = e;
                  onChangeValue('email', target.value);
                }}
              />
              <TextInput
                id="name"
                label="Name"
                value={user?.name}
                onChange={(e) => {
                  const { target } = e;
                  onChangeValue('name', target.value);
                }}
              />
              <TextInput
                id="reportCredits"
                label="Report credit"
                value={user?.reportCredits}
                type="number"
                min={0}
                onChange={(e) => {
                  const { target } = e;
                  onChangeValue(
                    'reportCredits',
                    parseInt(target?.value) > 0
                      ? parseInt(target?.value) || 0
                      : 0,
                  );
                }}
              />
              <p className="mb-4 text-content-primary font-medium">Role</p>
              <SelectInput
                options={[RoleUser.USER, RoleUser.ADMIN]}
                value={user?.role}
                onChange={(val) => onChangeValue('role', val)}
              />
              <TextInput
                id="password"
                label="Password"
                type="password"
                placeholder="••••••••"
                value={user?.password}
                onChange={(e) => {
                  const { target } = e;
                  onChangeValue('password', target.value);
                }}
              />
              <Button
                variant={ButtonVariant.primary}
                className="w-full flex justify-center"
                disabled={!user?.password || !user?.email || !user?.name}
                isLoading={isLoading}
                onClick={onSubmit}
              >
                Create User
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreate;
