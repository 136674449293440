import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonVariant,
  Link,
  Radio,
  TextInput,
} from 'components/common';
import { toastError, toastSuccess } from 'helpers/toast';
import { IResponseError } from 'types';
import { Brand, useUpdateBrandById } from 'apis/brands';

type BrandFormFields = {
  url: string;
  is_archived: boolean;
};

const EditBrandForm = ({ id, brand }: { id: string; brand: Brand }) => {
  const [brandFields, setBrandFields] = useState<BrandFormFields>({
    url: brand?.url,
    is_archived: brand?.is_archived,
  });

  const { mutate, isLoading, isSuccess, isError, error } =
    useUpdateBrandById(id);

  useEffect(() => {
    setBrandFields({
      url: brand?.url || '',
      is_archived: brand?.is_archived || false,
    });
  }, [brand]);

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Updated successfully');
    }
    if (isError && error) {
      const err = error as IResponseError;
      toastError(`${err?.response.data.message}`);
    }
  }, [isError, isSuccess, error]);

  const onChange = (key: string, value: boolean | string) => {
    setBrandFields({
      ...brandFields,
      [key]: value,
    });
  };

  const onUpdateBrand = () => {
    if (id) {
      mutate({
        ...brandFields,
      });
    }
  };

  return (
    <div className="w-96 bg-white rounded-lg shadow p-6 ">
      <div className="space-y-4">
        <h3>Brand Information</h3>
        <div>
          <div className="mb-2 text-content-primary font-medium">Owner</div>
          <div>
            {brand.owner ? (
              <Link
                asRouterLink
                routerLinkProps={{
                  to: `/users/${brand.owner_id}`,
                }}
                className="truncate"
              >
                {brand.owner?.user_metadata?.fullName || brand.owner.email}
              </Link>
            ) : (
              <span className="font-bold">User deleted</span>
            )}
          </div>
        </div>

        <TextInput
          id="url"
          label="Brand Url"
          value={brandFields?.url}
          onChange={(e) => {
            const { target } = e;
            onChange('url', target?.value);
          }}
        />
        <>
          <div className="mb-4 text-content-primary font-medium">Archived?</div>
          <div className="flex">
            <Radio
              id="is_archived"
              label="yes"
              checked={brandFields?.is_archived}
              onChange={() => onChange('is_archived', true)}
            />
            <Radio
              id="is_archived"
              label="no"
              className="ml-4"
              checked={!brandFields?.is_archived}
              onChange={() => onChange('is_archived', false)}
            />
          </div>
        </>

        <Button
          variant={ButtonVariant.primary}
          className="w-full  justify-center"
          isLoading={isLoading}
          onClick={onUpdateBrand}
        >
          Update brand
        </Button>
      </div>
    </div>
  );
};

export default EditBrandForm;
