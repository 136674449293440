import { useMutation, useQuery, useQueryClient } from 'react-query';
import baseApi from './base';
import {
  IBodySubscriptionUpdate,
  IBodyUserCreate,
  IBodyUserUpdate,
} from 'types/users';
import { Brand } from './brands';
import { Widget } from './widgets';
import { Plan } from './plans';

export enum Role {
  RAMMP_ADMIN = 'rammp-admin',
  USER = 'user',
}

export type SubScription = {
  plan: Plan;
  subscription: {
    price: number;
    interval: string;
    current_period_end: number;
    start_date: number;
    status: string;
    stripe_subscription_id: string;
    stripe_price_id: string;
    stripe_customer_id: string;
    payment_method: {
      card: {
        brand: string;
        exp_month: number;
        exp_year: number;
        last4: string;
      };
    };
  };
};

export type User = {
  email: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  email_verified: boolean;
  brands?: Brand[];
  user_metadata: {
    fullName?: string;
  };
  app_metadata: {
    enableLogoCustomize: boolean;
    role: Role;
    report_credit?: number;
    enableLeadGenTool: boolean;
  };
  subscription: SubScription;
  widget?: Widget;
};

export const GET_CURRENT_USER_KEY = 'GET/currentUser';
export const useGetCurrentUser = (enabled?: boolean) => {
  return useQuery(
    [GET_CURRENT_USER_KEY],
    async () => {
      const { data } = await baseApi.get('/users/get-user');
      return data as User;
    },
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );
};

export const GET_USERS_KEY = 'GET/users';
export const useGetUsers = (page: number) => {
  return useQuery(
    [GET_USERS_KEY, page],
    async () => {
      const { data } = await baseApi.get(`/admin/users`, {
        params: {
          page,
        },
      });
      return data;
    },
    { keepPreviousData: true },
  );
};

export const GET_USERS_ALL_KEY = 'GET/users-all';
export const useGetAllUsers = () => {
  return useQuery([GET_USERS_ALL_KEY], async () => {
    const { data } = await baseApi.get(`/admin/users-all`);
    return data;
  });
};

export const GET_USER_BY_ID_KEY = 'GET/user';
export const useGetUserById = (id: string) => {
  return useQuery(
    [GET_USER_BY_ID_KEY, id],
    async () => {
      const { data } = await baseApi.get(`/admin/users/${id}`);
      return data?.data as User;
    },
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );
};

export const CREATE_USER_KEY = 'POST/user';
export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [CREATE_USER_KEY],
    async (body: IBodyUserCreate) => {
      const { data } = await baseApi.post(`/admin/users`, body);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USERS_KEY]);
      },
    },
  );
};

export const UPDATE_USER_BY_ID_KEY = 'UPDATE/user';
export const useUpdateUserById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [UPDATE_USER_BY_ID_KEY],
    async (user: { id: string; body: IBodyUserUpdate }) => {
      const { data } = await baseApi.put(`/admin/users/${user.id}`, user.body);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USERS_KEY]);
        queryClient.invalidateQueries([GET_USER_BY_ID_KEY]);
      },
    },
  );
};

export const DELETE_USER_BY_ID_KEY = 'DELETE/user';
export const useDeleteUserById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [DELETE_USER_BY_ID_KEY],
    async (id: string) => {
      const { data } = await baseApi.delete(`/admin/users/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USERS_KEY]);
      },
    },
  );
};

export const UPDATE_USER_SUBSCRIPTION_KEY = 'UPDATE/user/subscriptions';
export const useUpdateUserSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [UPDATE_USER_SUBSCRIPTION_KEY],
    async (body: IBodySubscriptionUpdate) => {
      const { data } = await baseApi.post(`/admin/users/subscriptions`, body);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USERS_KEY]);
        queryClient.invalidateQueries([GET_USER_BY_ID_KEY]);
      },
    },
  );
};
