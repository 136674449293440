import React, {
  FunctionComponent,
  ReactNode,
  ButtonHTMLAttributes,
} from 'react';
import cn from 'classnames';
import { IconSize, IconWrapper } from '../IconWrapper';
import { activeStyle } from 'styles/constants';

export enum ButtonVariant {
  primary = 'primary',
  outline = 'outline',
  text = 'text',
  danger = 'danger',
  link = 'link',
}

const getVariantStyles = (variant: ButtonVariant, disabled: boolean) => {
  switch (variant) {
    case ButtonVariant.primary:
      return cn(
        'rounded-lg',
        !disabled
          ? 'text-content-primary-reverse bg-accent-main hover:bg-accent-hover hover:shadow-md dark:bg-content-primary dark:hover:bg-surface-overlay-reverse'
          : 'bg-action-hover text-content-tetriary',
      );
    case ButtonVariant.outline:
      return cn(
        'border-surface-div border rounded-lg',
        !disabled
          ? 'text-content-primary bg-surface-overlay dark:bg-transparent hover:bg-action-hover dark:hover:bg-action-hover'
          : 'bg-action-hover border-transparent text-content-tetriary',
      );
    case ButtonVariant.text:
      return cn(
        'rounded-lg',
        !disabled
          ? 'text-content-primary hover:bg-action-hover'
          : 'text-content-tetriary',
      );
    case ButtonVariant.danger:
      return cn(
        'rounded-lg',
        !disabled
          ? 'text-content-primary-reverse dark:text-content-primary bg-error-main hover:bg-error-hover hover:shadow-md'
          : 'bg-action-hover text-content-tetriary',
      );
    case ButtonVariant.link:
      return cn(
        'rounded-md',
        !disabled
          ? 'text-accent-main hover:bg-action-hover'
          : 'text-content-tetriary',
      );
    default:
      return '';
  }
};

const getIconVariantStyles = (variant: ButtonVariant, disabled: boolean) => {
  switch (variant) {
    case ButtonVariant.primary:
      return !disabled ? '' : 'text-content-tetriary';
    case ButtonVariant.outline:
      return `text-content-secondary duration-250 ${
        !disabled ? 'group-hover:text-content-primary' : 'text-content-tetriary'
      }`;
    case ButtonVariant.text:
      return `text-content-secondary duration-250 ${
        !disabled ? 'group-hover:text-content-primary' : 'text-content-tetriary'
      }`;
    default:
      return '';
  }
};

export type ButtonProps = {
  variant?: ButtonVariant;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  onClick?: VoidFunction;
  narrow?: boolean;
  prefixIconSize?: IconSize;
  prefixIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  suffixIconSize?: IconSize;
  suffixIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FunctionComponent<ButtonProps> = ({
  className = '',
  variant = ButtonVariant.primary,
  prefixIcon,
  prefixIconSize = IconSize.md,
  suffixIcon,
  suffixIconSize = IconSize.md,
  children,
  narrow,
  disabled = false,
  isLoading = false,
  ...props
}) => {
  return (
    <button
      className={cn(
        getVariantStyles(variant, disabled || isLoading),
        'group duration-250 font-medium flex items-center',
        narrow ? 'px-1 py-0.5' : 'py-2 px-3',
        !disabled ? activeStyle : '',
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {prefixIcon && (
        <IconWrapper
          icon={prefixIcon}
          size={prefixIconSize}
          className={cn(
            getIconVariantStyles(variant, disabled || isLoading),
            'mr-2',
          )}
        />
      )}
      {children}
      {suffixIcon && (
        <IconWrapper
          icon={suffixIcon}
          size={suffixIconSize}
          className={cn(
            getIconVariantStyles(variant, disabled || isLoading),
            'ml-2',
          )}
        />
      )}
    </button>
  );
};
