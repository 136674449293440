import React, { useEffect } from 'react';
import { Button, ButtonVariant, Loader, LoaderSize } from 'components/common';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { toastError } from 'helpers/toast';
import { IResponseError } from 'types';
import EditPlanForm from './EditPlanForm';
import { useGetPlanById } from 'apis/plans';

const PlanEdit = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { data, isLoading, isError, error } = useGetPlanById(params?.id);

  useEffect(() => {
    if (isError && error) {
      const err = error as IResponseError;
      toastError(`${err?.response.data.message}`);
    }
  }, [isError, error]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">
        Edit plan: {data?.name || params?.id}
      </h1>
      <Button
        variant={ButtonVariant.text}
        onClick={() => navigate('/plans')}
        prefixIcon={ChevronLeftIcon}
        className="mb-4"
      >
        Back
      </Button>
      {isLoading && !data ? (
        <div className="w-full flex items-center justify-center h-80">
          <Loader size={LoaderSize.xxl} />
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          {data && <EditPlanForm plan={data} id={params?.id} />}
        </div>
      )}
    </div>
  );
};

export default PlanEdit;
