import React, { useEffect } from 'react';
import {
  Button,
  ButtonVariant,
  Column,
  Link,
  Loader,
  LoaderSize,
  Table,
} from 'components/common';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { toastError } from 'helpers/toast';
import { IResponseError } from 'types';
import EditWidgetForm from './EditWidgetForm';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import cn from 'classnames';
import { useGetWidgetById } from 'apis/widgets';
import { Report } from 'apis/brands';

const columns: Column[] = [
  {
    id: 'scores',
    label: 'Scores',
    customCellRender(value) {
      return value?.rammpScore;
    },
  },
  {
    id: 'created_at',
    label: 'Date generated',
    customCellRender(value, row) {
      return moment(value).format('LLL');
    },
  },
  {
    id: 'widgetDataCollect-email',
    label: 'Email',
    customCellRender(value, row) {
      return row.widgetDataCollect?.email;
    },
  },
  {
    id: 'widgetDataCollect-brandUrl',
    label: 'Brand Url',
    customCellRender(value, row) {
      return row.widgetDataCollect?.brandUrl;
    },
  },
  {
    id: 'widgetDataCollect-domain',
    label: 'From domain',
    customCellRender(value, row) {
      return row.widgetDataCollect?.domain;
    },
  },
  {
    id: 'id',
    label: '',
    customCellRender(value, row) {
      return (
        <Link
          href={`${
            process.env.REACT_APP_FRONT_END_URL || 'https://get.rammp.com'
          }/publicReport?id=${value}`}
          target="_blank"
        >
          View report
        </Link>
      );
    },
  },
];

const WidgetEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading, isError, error } = useGetWidgetById(params?.id);

  useEffect(() => {
    if (isError && error) {
      const err = error as IResponseError;
      toastError(`${err?.response.data.message}`);
    }
  }, [isError, error]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">
        Widget from user: {data?.user?.email}
      </h1>
      <Button
        variant={ButtonVariant.text}
        onClick={() => navigate('/widgets')}
        prefixIcon={ChevronLeftIcon}
        className="mb-4"
      >
        Back
      </Button>
      {isLoading && !data ? (
        <div className="w-full flex items-center justify-center h-80">
          <Loader size={LoaderSize.xxl} />
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          {data && <EditWidgetForm widget={data} />}
          <div className="bg-white rounded-lg shadow p-6 max-h-[334px]">
            <h3 className="mb-4">
              Reports generated ({data?.reports?.length})
            </h3>
            <Table
              classNameThead="bg-surface-base text-left"
              columns={columns}
              rows={data?.reports || []}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WidgetEdit;
