import React, { ReactNode } from 'react';
import cn from 'classnames';
import { IconWrapper, Link } from 'components/common';

type SidebarItemProps = {
  className?: string;
  label?: string;
  prefixIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  suffixComponent?: ReactNode;
  active?: boolean;
  onClick?: VoidFunction;
  url?: string;
};

const SidebarItem = (props: SidebarItemProps) => {
  const {
    label,
    prefixIcon,
    suffixComponent,
    active,
    onClick,
    className,
    url,
  } = props;

  const Component = url ? Link : 'div';
  const routerProps = {
    to: url,
  };

  return (
    <Component
      className={cn(
        'h-[30px] flex items-center justify-between px-1.5 rounded',
        'cursor-pointer z-2 !ring-0 !ring-offset-0',
        active
          ? 'text-content-primary hover:!text-content-primary bg-action-selected '
          : 'text-content-secondary hover:!text-content-secondary bg-surface-base hover:bg-action-hover',
        className,
      )}
      onClick={onClick}
      asRouterLink={!!url}
      routerLinkProps={routerProps}
    >
      <div className="flex items-center">
        <IconWrapper icon={prefixIcon} className="mr-3.5" />
        <span>{label}</span>
      </div>
      {suffixComponent}
    </Component>
  );
};

export default SidebarItem;
