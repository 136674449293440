import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonVariant,
  IconSize,
  IconWrapper,
  Link,
  Loader,
  LoaderSize,
} from 'components/common';
import { useNavigate, useParams } from 'react-router-dom';
import { Role, useGetUserById } from 'apis/users';
import { InitUser, RoleUser } from 'types/users';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import EditUserForm from './EditUserForm';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { centToStringDollar } from 'helpers';
import moment from 'moment';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { ReactComponent as Cog } from 'assets/icons/cog.svg';
import EditSubscriptionModal from './EditSubscriptionModal';

const UserEdit = () => {
  const [initUser, setInitUser] = useState<InitUser>(null);
  const navigate = useNavigate();
  const params = useParams();
  const { data: user, isLoading, isFetching } = useGetUserById(params?.id);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setInitUser({
        email: user?.email,
        name: user?.user_metadata?.fullName || '',
        enableLogoCustomize: user?.app_metadata?.enableLogoCustomize,
        enableLeadGenTool: user?.app_metadata?.enableLeadGenTool,
        role:
          user?.app_metadata?.role === Role.RAMMP_ADMIN
            ? RoleUser.ADMIN
            : RoleUser.USER,
        reportCredits: user?.app_metadata?.report_credit || 0,
      });
    }
  }, [user]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">Edit user: {params?.id}</h1>
      <Button
        variant={ButtonVariant.text}
        onClick={() => navigate('/users')}
        prefixIcon={ChevronLeftIcon}
        className="mb-4"
      >
        Back
      </Button>
      {isLoading || isFetching || !initUser ? (
        <div className="w-full flex items-center justify-center h-80">
          <Loader size={LoaderSize.xxl} />
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          <EditUserForm
            userData={initUser}
            user_id={params?.id}
            subscription={user?.subscription}
            widget={user?.widget}
          />
          <div className="w-96 bg-white rounded-lg shadow p-6">
            <h3 className="mb-4">Brands ({user?.brands?.length})</h3>
            <PerfectScrollbar className={'h-[calc(100%-20px)] max-h-[600px]'}>
              {user?.brands.map((brand) => (
                <div
                  key={brand.id + '1'}
                  className="py-2 border-b border-surface-div"
                >
                  <Link
                    asRouterLink
                    routerLinkProps={{ to: `/brands/${brand.id}` }}
                  >
                    {brand.url}
                  </Link>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
          <div className="w-96 bg-white rounded-lg shadow p-6 ">
            <h3 className="mb-4">Subscription</h3>
            {user?.subscription ? (
              <div>
                <div className="font-medium mb-1">Plan:</div>
                <h3 className="text-accent-main">
                  {user?.subscription?.plan?.name}
                </h3>
                <div className="font-medium mb-1 mt-2">Price:</div>
                <h3 className="text-accent-main">
                  ${centToStringDollar(user?.subscription?.subscription.price)}{' '}
                  / {user?.subscription?.subscription.interval}
                </h3>
                <div className="font-medium mb-1 mt-2">Next billing date:</div>
                <h3 className="text-accent-main">
                  {moment(
                    user?.subscription?.subscription?.current_period_end,
                  ).format('LLL')}
                </h3>
                <div className="font-medium mb-1 mt-2">Payment method:</div>
                <div className="text-accent-main">
                  {user?.subscription?.subscription?.payment_method?.card ? (
                    <>
                      <h3 className="text-accent-main">
                        •••• •••• ••••{' '}
                        {
                          user?.subscription?.subscription?.payment_method?.card
                            ?.last4
                        }
                        {'  '}
                        <span className="text-content-secondary font-normal text-base">
                          Expiry{' '}
                          {
                            user?.subscription?.subscription?.payment_method
                              ?.card?.exp_month
                          }
                          /
                          {
                            user?.subscription?.subscription?.payment_method
                              ?.card?.exp_year
                          }
                        </span>
                      </h3>
                    </>
                  ) : (
                    "User doesn't have any payment method set up"
                  )}
                </div>
                <a
                  href={`https://dashboard.stripe.com/subscriptions/${user?.subscription?.subscription?.stripe_subscription_id}`}
                  target="__blank"
                  rel="noreferrer"
                >
                  <Button variant={ButtonVariant.outline} className="mt-2">
                    View on Stripe
                    <IconWrapper
                      className="inline-block ml-2"
                      icon={ExternalLink}
                      size={IconSize.lg}
                    />
                  </Button>
                </a>
              </div>
            ) : (
              <div>User doesn't have any subscription</div>
            )}
            <Button
              variant={ButtonVariant.outline}
              className="mt-2"
              onClick={() => setOpen(true)}
            >
              <IconWrapper
                className="inline-block mr-2"
                icon={Cog}
                size={IconSize.md}
              />
              Edit subscription
            </Button>
          </div>
        </div>
      )}
      <EditSubscriptionModal
        user={user}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </div>
  );
};

export default UserEdit;
