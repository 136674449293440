import { useMutation, useQuery, useQueryClient } from 'react-query';
import baseApi from './base';

export type Plan = {
  id: string;
  currency?: string;
  is_private?: boolean;
  is_active: boolean;
  is_payable: boolean;
  monthly_price: number;
  yearly_price: number;
  stripe_monthly_price_id: string;
  stripe_yearly_price_id: string;
  name: string;
  plan_features: string[];
  stripe_product_id: string;
  urls_count: number;
  plan_type: string;
  call_us_url?: string;
  features?: {
    whiteLabel: boolean;
    leadGen: boolean;
    totalBrands: number;
  };
};

export const GET_PLANS = 'GET/plans';
export const useGetPlans = () => {
  return useQuery([GET_PLANS], async () => {
    const { data } = await baseApi.get(`/admin/plans`);
    return data as Plan[];
  });
};

export const GET_PLAN_BY_ID = 'GET/plan';
export const useGetPlanById = (id: string) => {
  return useQuery([GET_PLAN_BY_ID, id], async () => {
    const { data } = await baseApi.get(`/admin/plans/${id}`);
    return data as Plan;
  });
};

export const UPDATE_PLAN_BY_ID = 'UPDATE/plan';
export const useUpdatePlanById = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    [UPDATE_PLAN_BY_ID, id],
    async (payload: Partial<Plan>) => {
      const { data } = await baseApi.put(`/admin/plans/${id}`, payload);
      return data as Plan;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PLAN_BY_ID]);
        queryClient.invalidateQueries([GET_PLANS]);
      },
    },
  );
};
