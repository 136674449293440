import React, {
  FunctionComponent,
  ReactNode,
  Children,
  HTMLAttributes,
  isValidElement,
  cloneElement,
  ReactElement,
} from 'react';
import cn from 'classnames';
import { Button } from '../Button/Button';
import { IconButton, IconButtonSize } from '../IconButton';

export enum ButtonGroupVariant {
  primary = 'primary',
  outline = 'outline',
  text = 'text',
  danger = 'danger',
  link = 'link',
}

export type ButtonGroupProps = {
  variant?: ButtonGroupVariant;
  className?: string;
  children?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({
  className = '',
  variant = ButtonGroupVariant.primary,
  children,
  ...props
}) => {
  const childArray = Children.toArray(children);
  const isAllButtons = childArray.every(
    (child) =>
      isValidElement(child) &&
      (child.type === Button || child.type === IconButton),
  );

  if (!isAllButtons) {
    console.error(
      'All children of ButtonGroup must be Button or IconButton components.',
    );
    return null;
  }

  return (
    <div className={cn(className, 'flex')} {...props}>
      {Children.map(children, (child, index) => {
        if (
          isValidElement(child) &&
          (child.type === Button || child.type === IconButton)
        ) {
          const options: any = {
            className: cn(child.props.className, 'active:relative', {
              'rounded-l-md rounded-r-none border-r border-surface-div':
                index === 0,
              'rounded-none border-r border-surface-div border-l-0':
                index !== 0 && index !== childArray.length - 1,
              'rounded-r-md rounded-l-none border-l-0':
                index === childArray.length - 1,
            }),
            variant: child.props.variant || variant,
          };

          if (child.type === IconButton) {
            options.size = IconButtonSize.xl;
          }

          return cloneElement(child as ReactElement<any>, options);
        }
        return child;
      })}
    </div>
  );
};
