import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonVariant,
  IconSize,
  IconWrapper,
  Link,
  Radio,
  TextInput,
  Tooltip,
} from 'components/common';
import { toastError, toastSuccess } from 'helpers/toast';
import { IResponseError } from 'types';
import { Plan, useUpdatePlanById } from 'apis/plans';
import { centToStringDollar } from 'helpers';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { StringArrayInput } from 'components/common/StringArrayInput';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';

type PlanFormFields = {
  name: string;
  plan_features: string[];
  whiteLabel: boolean;
  leadGen: boolean;
  totalBrands: number;
  is_active: boolean;
};

const EditPlanForm = ({ id, plan }: { id: string; plan: Plan }) => {
  const [planFields, setPlanFields] = useState<PlanFormFields>({
    name: plan?.name,
    plan_features: plan.plan_features,
    whiteLabel: plan?.features?.whiteLabel,
    leadGen: plan?.features?.leadGen,
    totalBrands: plan?.features?.totalBrands,
    is_active: plan?.is_active,
  });

  const { mutate, isLoading, isSuccess, isError, error } =
    useUpdatePlanById(id);

  useEffect(() => {
    setPlanFields({
      name: plan?.name || '',
      plan_features: plan.plan_features || [''],
      whiteLabel: plan?.features?.whiteLabel || false,
      leadGen: plan?.features?.leadGen || false,
      totalBrands: plan?.features?.totalBrands || 0,
      is_active: plan?.is_active || false,
    });
  }, [plan]);

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Updated successfully');
    }
    if (isError && error) {
      const err = error as IResponseError;
      toastError(`${err?.response.data.message}`);
    }
  }, [isError, isSuccess, error]);

  const onChange = (key: string, value: boolean | string | string[]) => {
    setPlanFields({
      ...planFields,
      [key]: value,
    });
  };

  const onUpdate = () => {
    if (id) {
      mutate({
        name: planFields?.name,
        plan_features: planFields.plan_features,
        features: {
          whiteLabel: planFields?.whiteLabel,
          leadGen: planFields?.leadGen,
          totalBrands: Number(planFields?.totalBrands) || 0,
        },
      });
    }
  };

  return (
    <div className="w-96 bg-white rounded-lg shadow p-6 ">
      <div className="space-y-4">
        <h3>Plan Information</h3>

        <div className="mb-2 text-content-primary font-medium">Plan type</div>
        <h3 className="text-accent-main capitalize">{plan.plan_type}</h3>

        <div className="mb-2 text-content-primary font-medium">
          Stripe product id
        </div>
        <div>
          <Link
            href={`https://dashboard.stripe.com/products/${plan.stripe_product_id}`}
            className="truncate w-fit"
            target="__blank"
            rel="noreferrer"
          >
            {plan.stripe_product_id}{' '}
            <IconWrapper
              className="inline-block ml-2"
              icon={ExternalLink}
              size={IconSize.lg}
            />
          </Link>
        </div>

        <div className="mb-2 text-content-primary font-medium">
          Monthly price
        </div>
        <div>
          <Link
            href={`https://dashboard.stripe.com/prices/${plan.stripe_monthly_price_id}`}
            className="truncate w-fit"
            target="__blank"
            rel="noreferrer"
          >
            ${centToStringDollar(plan.monthly_price)} /month{' '}
            <IconWrapper
              className="inline-block ml-2"
              icon={ExternalLink}
              size={IconSize.lg}
            />
          </Link>
        </div>

        <div className="mb-2 text-content-primary font-medium">
          Yearly price
        </div>
        <div>
          <Link
            href={`https://dashboard.stripe.com/prices/${plan.stripe_yearly_price_id}`}
            className="truncate w-fit"
            target="__blank"
            rel="noreferrer"
          >
            ${centToStringDollar(plan.yearly_price)} /year{' '}
            <IconWrapper
              className="inline-block ml-2"
              icon={ExternalLink}
              size={IconSize.lg}
            />
          </Link>
        </div>

        <TextInput
          id="url"
          label="Display name"
          value={planFields?.name}
          onChange={(e) => {
            const { target } = e;
            onChange('name', target?.value);
          }}
        />

        <div className="mb-4 text-content-primary font-medium">Description</div>

        <StringArrayInput
          values={planFields.plan_features}
          onChange={(values: string[]) => onChange('plan_features', values)}
        />

        <TextInput
          id="totalBrands"
          label="Total brands"
          value={planFields?.totalBrands}
          type="number"
          min={0}
          onChange={(e) => {
            const { target } = e;
            onChange('totalBrands', target?.value.replace('-', '') || '');
          }}
        />

        <div className="mb-4 text-content-primary font-medium">
          White Label feature
        </div>
        <div className="flex">
          <Radio
            id="whiteLabel-enabled"
            label="enabled"
            checked={planFields.whiteLabel}
            onChange={() => onChange('whiteLabel', true)}
          />
          <Radio
            id="whiteLabel-disabled"
            label="disabled"
            className="ml-4"
            checked={!planFields.whiteLabel}
            onChange={() => onChange('whiteLabel', false)}
          />
        </div>

        <div className="mb-4 text-content-primary font-medium">
          Lead gen feature
        </div>
        <div className="flex">
          <Radio
            id="leadGen-enabled"
            label="enabled"
            checked={planFields.leadGen}
            onChange={() => onChange('leadGen', true)}
          />
          <Radio
            id="leadGen-disabled"
            label="disabled"
            className="ml-4"
            checked={!planFields.leadGen}
            onChange={() => onChange('leadGen', false)}
          />
        </div>

        <div className="mb-4 text-content-primary font-medium">
          Active on Rammp app
          <Tooltip
            title='Enable you want to show the plan on Rammp app (except for Private plan). Disable if you want to hide the plan on Rammp app and "This plan is not available" text will show up instead'
            className="ml-2 !inline-block"
          >
            <IconWrapper icon={InfoIcon} className="text-content-secondary" />
          </Tooltip>
        </div>
        <div className="flex">
          <Radio
            id="is_active-enabled"
            label="enabled"
            checked={planFields.is_active}
            onChange={() => onChange('is_active', true)}
          />
          <Radio
            id="is_active-disabled"
            label="disabled"
            className="ml-4"
            checked={!planFields.is_active}
            onChange={() => onChange('is_active', false)}
          />
        </div>
        <Button
          variant={ButtonVariant.primary}
          className="w-full  justify-center"
          isLoading={isLoading}
          onClick={onUpdate}
        >
          Update Plan
        </Button>
      </div>
    </div>
  );
};

export default EditPlanForm;
