/* eslint import/no-extraneous-dependencies: off */
import auth0 from 'auth0-js';

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_USER_SCOPE,
  REACT_APP_AUTH0_SOCIAL_DOMAIN,
} = process.env;

export const auth = new auth0.WebAuth({
  domain: REACT_APP_AUTH0_DOMAIN ?? '',
  clientID: REACT_APP_AUTH0_CLIENT_ID ?? '',
  scope: `${REACT_APP_AUTH0_USER_SCOPE} offline_access` ?? '',
  responseType: 'token',
  audience: 'offline',
});

export const socialAuth = new auth0.WebAuth({
  domain: REACT_APP_AUTH0_SOCIAL_DOMAIN ?? '',
  clientID: REACT_APP_AUTH0_CLIENT_ID ?? '',
  scope: `${REACT_APP_AUTH0_USER_SCOPE} offline_access` ?? '',
  audience: 'offline',
});
