export const getRedirectUri = () =>
  `${window.location.protocol}//${window.location.host}/authenticate`;

export const getHashValue = (hash: string, key: string) => {
  const matches = hash.match(new RegExp(key + '=([^&]*)'));
  return matches ? matches[1] : null;
};

export const centToStringDollar = (price: number) => {
  return (price / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });
};
