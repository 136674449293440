import React, { FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import { SIDEBAR_WIDTH } from './Modal';

export type TabItem = {
  label: string | ReactNode;
  id: string | number;
  disabled?: boolean;
};
export interface ModalSidebarProps {
  className?: string;
  tabs?: TabItem[];
  activeTab?: string | number;
  onTabChange?: (key: string | number) => void;
}

export const ModalSidebar: FunctionComponent<ModalSidebarProps> = ({
  className,
  tabs = [],
  activeTab = '',
  onTabChange,
}) => {
  return (
    <div
      style={{
        width: `${SIDEBAR_WIDTH}px`,
      }}
      className={cn('w-[216px] p-4 border-r border-surface-div', className)}
    >
      {tabs.map((i) => (
        <div
          key={i.id}
          className={cn(' px-1 py-1.5 rounded cursor-pointer select-none', {
            'text-content-secondary': activeTab !== i.id,
            'hover:bg-action-hover': activeTab !== i.id,
            'bg-action-selected text-content-primary font-medium':
              activeTab === i.id,
          })}
          onClick={() => onTabChange?.(i.id)}
        >
          {i.label}
        </div>
      ))}
    </div>
  );
};
