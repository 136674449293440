import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { IconWrapper } from '../IconWrapper';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg';

export enum ProgressSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

const getSizeStyles = (variant: ProgressSize) => {
  switch (variant) {
    case ProgressSize.sm:
      return 'w-3 h-3';
    case ProgressSize.md:
      return 'w-4 h-4';
    case ProgressSize.lg:
      return 'w-5 h-5';
    case ProgressSize.xl:
      return 'w-6 h-6';
    default:
      return 'w-4 h-4';
  }
};

export interface CircularProgressProps {
  className?: string;
  size?: ProgressSize;
  progress?: string | number;
}

export const CircularProgress: FunctionComponent<CircularProgressProps> = ({
  progress,
  className,
  size = ProgressSize.md,
}) => {
  const radius = 6.5;
  const strokeWidth = 1.5;
  const circumference = 2 * Math.PI * radius;

  const dashOffset = circumference - (Number(progress) / 100) * circumference;

  const isCompleted = Number(progress) === 100;

  return (
    <div
      className={cn(
        getSizeStyles(size),
        'text-success-main dark:text-content-primary',
        className,
      )}
    >
      {isCompleted ? (
        <IconWrapper className={'w-full h-full'} icon={CheckCircleIcon} />
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          className="w-full h-full"
        >
          <circle
            cx="8"
            cy="8"
            r={radius}
            strokeWidth={strokeWidth}
            fill="none"
            className="stroke-surface-alt"
          ></circle>
          <circle
            cx="8"
            cy="8"
            r={radius}
            strokeWidth={strokeWidth}
            fill="none"
            stroke="currentColor"
            style={{ transform: 'rotate(-90deg) translateX(-16px)' }}
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
          ></circle>
        </svg>
      )}
    </div>
  );
};
