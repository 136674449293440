/* eslint import/no-extraneous-dependencies: off */
import React, { useState } from 'react';
import { Button, ButtonVariant, Logo, TextInput } from 'components/common';
import { useNavigate } from 'react-router-dom';
// Services
import { auth, socialAuth } from 'services/auth0.service';
import { Auth0Error } from 'auth0-js';

import { getRedirectUri } from 'helpers';
import InvalidIcon from 'assets/images/InvalidIcon.png';
import GoogleLogo from 'assets/images/Image_3@2x.png';

const {
  REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE,
  REACT_APP_AUTH0_REALM,
  REACT_APP_AUTH0_USER_SCOPE,
} = process.env;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [invalidCredential, setInvalidCredential] = useState(false);
  const navigate = useNavigate();
  const localStorageUser = localStorage.getItem('user');
  const user = localStorageUser ? JSON.parse(localStorageUser) : {};
  const accessToken = localStorage.getItem('accessToken');

  if (user.email_verified) {
    if (accessToken) navigate('/');
  }

  const onInputEmail = (e) => {
    const { target } = e;
    setEmail(target?.value);
  };

  const onInputPassword = (e) => {
    const { target } = e;
    setPassword(target?.value);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    auth.login(
      {
        email,
        password,
        realm: REACT_APP_AUTH0_REALM,
        redirectUri: getRedirectUri(),
        responseType: `${REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE} id_token code`,
        scope: `${REACT_APP_AUTH0_USER_SCOPE} offline_access`,
      },
      function (err: Auth0Error | null, result: any) {
        setIsLoading(false);
        if (err) {
          setInvalidCredential(true);
        }
      },
    );
  };

  const GoogleSignIn = () => {
    socialAuth.authorize({
      connection: 'google-oauth2',
      redirectUri: getRedirectUri(),
      responseType: `${REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE} code`,
    });
  };

  function invalid() {
    if (invalidCredential) {
      return (
        <div className="flex p-3 mt-3 bg-[#ff000012] text-error-main w-full rounded-md">
          <img src={InvalidIcon} alt="Invalid" className="w-5" />
          <span className="ml-2">Invalid credentials</span>
        </div>
      );
    }
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center py-8">
        <div className="flex items-center mb-20 mt-10">
          <Logo className="w-56" />
        </div>
        <div className="w-96 bg-white rounded-lg shadow ">
          <div className="p-6 space-y-4">
            <form className="space-y-4" onSubmit={onSubmit}>
              <Button
                variant={ButtonVariant.outline}
                className="w-full flex justify-center"
                type="button"
                onClick={GoogleSignIn}
              >
                <img src={GoogleLogo} alt="Google" className="w-5 mr-2" />
                Sign in with Google
              </Button>

              <p className="text-center my-3">OR</p>
              <TextInput
                label="Email"
                placeholder="Email address"
                value={email}
                onChange={onInputEmail}
              />
              <TextInput
                label="Password"
                type="password"
                placeholder="••••••••"
                value={password}
                onChange={onInputPassword}
              />
              {invalid()}
              <Button
                variant={ButtonVariant.primary}
                className="w-full flex justify-center"
                isLoading={isLoading}
                type="submit"
              >
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
