/* eslint import/no-extraneous-dependencies: off */
import { toast } from 'react-toastify';

export const toastError = (message, callBack?) => {
  toast.info(message, {
    type: 'error',
    onClose: () => {
      if (callBack) callBack();
    },
  });
};

export const toastSuccess = (message, callBack?) => {
  toast.info(message, {
    type: 'success',
    onClose: callBack,
  });
};
