import { useLocalStorage } from 'hooks/useLocalStorage';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}
export const ThemeContext = createContext<{
  darkTheme?: boolean;
  toggleDarkTheme: (val: boolean) => void;
  hideSidebar: boolean;
  setHideSidebar: (hideSidebar: boolean) => void;
  isTablet: boolean;
  hideSubSidebar: boolean;
  setHideSubSidebar: (hideSidebar: boolean) => void;
}>({
  darkTheme: undefined,
  toggleDarkTheme: () => {},
  hideSidebar: false,
  setHideSidebar: () => {},
  isTablet: false,
  hideSubSidebar: false,
  setHideSubSidebar: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);

type ThemeProviderProps = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const localStorage = useLocalStorage();
  const [darkTheme, setDarkTheme] = useState<boolean>();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [hideSidebar, setHideSidebar] = useState<boolean>(true); // This state is only effective in < lg
  const [hideSubSidebar, setHideSubSidebar] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    const theme = localStorage?.getItem('theme');
    if (theme) {
      const isDarkTheme = theme === Theme.Dark;
      if (isDarkTheme) {
        document.documentElement.classList.add(Theme.Dark);
      }
      setDarkTheme(isDarkTheme);
    }
    setTimeout(() => {
      setIsInitialized(true);
    }, 300);
  }, [localStorage]);

  const toggleDarkTheme = useCallback(
    (val: boolean) => {
      if (val) {
        document.documentElement.classList.add(Theme.Dark);
      } else {
        document.documentElement.classList.remove(Theme.Dark);
      }

      setDarkTheme(val);
      localStorage?.setItem('theme', val ? Theme.Dark : Theme.Light);
    },
    [localStorage],
  );

  return (
    <ThemeContext.Provider
      value={{
        darkTheme,
        toggleDarkTheme,
        isTablet,
        hideSidebar,
        setHideSidebar,
        hideSubSidebar,
        setHideSubSidebar,
      }}
    >
      {isInitialized ? children : null}
    </ThemeContext.Provider>
  );
}
