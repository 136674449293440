import React, {
  FunctionComponent,
  ReactNode,
  AnchorHTMLAttributes,
} from 'react';
import cn from 'classnames';
import { IconSize, IconWrapper } from '../IconWrapper';
import { activeStyle } from 'styles/constants';
import {
  LinkProps as RouterLinkProps,
  Link as RouterLink,
} from 'react-router-dom';

export enum LinkVariant {
  link = 'link',
  monochrome = 'monochrome', //Meaning black and white
}

const getVariantStyles = (variant: LinkVariant, disabled: boolean) => {
  switch (variant) {
    case LinkVariant.link:
      return cn(
        !disabled
          ? 'text-accent-main hover:bg-action-hover cursor-pointer'
          : 'text-content-tetriary hover:text-content-tetriary cursor-default',
      );
    case LinkVariant.monochrome:
      return cn(
        !disabled
          ? 'text-content-primary hover:text-content-primary cursor-pointer hover:bg-action-hover'
          : 'text-content-tetriary hover:text-content-tetriary cursor-default',
      );
    default:
      return '';
  }
};

export type LinkProps = {
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  prefixIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  variant?: LinkVariant;
  asRouterLink?: boolean;
  routerLinkProps?: RouterLinkProps;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link: FunctionComponent<LinkProps> = ({
  className = '',
  prefixIcon,
  children,
  disabled = false,
  variant = LinkVariant.link,
  asRouterLink = false,
  routerLinkProps,
  ...props
}) => {
  const Component = asRouterLink ? RouterLink : 'a';

  if (asRouterLink && !routerLinkProps) {
    console.error('routerLinkProps is required if asRouterLink is true');
    return null;
  }
  return (
    <Component
      className={cn(
        getVariantStyles(variant, disabled),
        'duration-250 font-medium flex items-center px-1 py-0.5  rounded-md',
        !disabled ? activeStyle : '',
        className,
      )}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
      {...(routerLinkProps || { to: '' })}
      {...props}
    >
      {prefixIcon && (
        <IconWrapper icon={prefixIcon} size={IconSize.md} className={'mr-2'} />
      )}
      {children}
    </Component>
  );
};
