import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
import cn from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { useThemeContext } from 'contexts/ThemeContext';

export type LogoVariantProps = {
  className?: string;
  linkProps?: LinkProps;
} & ImgHTMLAttributes<HTMLImageElement>;

export const Logo: FunctionComponent<LogoVariantProps> = ({
  className = '',
  linkProps,
  ...props
}) => {
  const { darkTheme } = useThemeContext();

  if (linkProps) {
    <Link {...linkProps}>
      <img
        src={'/rammp-logo.svg'}
        className={cn(className, 'w-32')}
        alt=""
        {...props}
      />
    </Link>;
  }
  return (
    <img
      src={'/rammp-logo.svg'}
      className={cn(className, 'w-32')}
      alt=""
      {...props}
    />
  );
};
