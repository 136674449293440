/* eslint import/no-extraneous-dependencies: off */
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';

import './styles/index.css';
import './styles/font.css';
import { getRedirectUri } from 'helpers';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_AUTH0_LOGIN_REDIRECT_URL,
} = process.env;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 5 * 60 * 1000, // 5 min
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient} contextSharing={true}>
    <Router>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN ?? ''}
        clientId={REACT_APP_AUTH0_CLIENT_ID ?? ''}
        redirectUri={REACT_APP_AUTH0_LOGIN_REDIRECT_URL ?? getRedirectUri()}
      >
        <App />
      </Auth0Provider>
      ,
    </Router>
  </QueryClientProvider>,
);
