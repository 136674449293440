import { useEffect, useState } from 'react';

export const useLocalStorage = () => {
  const [isLocalStorageAvailable, setIsLocalStorageAvailable] = useState(false);

  useEffect(() => {
    const testKey = 'test';
    try {
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      setIsLocalStorageAvailable(true);
    } catch (error) {
      setIsLocalStorageAvailable(false);
    }
  }, []);

  return isLocalStorageAvailable ? localStorage : null;
};
