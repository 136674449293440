import { useQuery } from 'react-query';
import baseApi from './base';

export const GET_USERS_KEY = 'GET/overview';
export const useGetOverviewData = () => {
  return useQuery([GET_USERS_KEY], async () => {
    const { data } = await baseApi.get(`/admin/overview`);
    return data;
  });
};
