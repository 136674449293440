import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonVariant,
  Link,
  Radio,
  SelectInput,
  TextInput,
} from 'components/common';
import { Role, SubScription, useUpdateUserById } from 'apis/users';
import { InitUser, RoleUser } from 'types/users';
import { toastError, toastSuccess } from 'helpers/toast';
import { Widget } from 'apis/widgets';

const EditUserForm = ({
  user_id,
  userData,
  subscription,
  widget,
}: {
  user_id: string;
  userData: InitUser;
  subscription: SubScription;
  widget?: Widget;
}) => {
  const [user, setUser] = useState<InitUser>(userData);

  const { mutateAsync, isLoading } = useUpdateUserById();

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  const isLoginWithGoogle = user_id.includes('google-oauth2|');

  const onChange = (
    key: string,
    value: boolean | RoleUser | string | number,
  ) => {
    setUser({
      ...user,
      [key]: value,
    });
  };

  const onUpdateUser = async () => {
    if (user_id) {
      const body = {
        user_metadata: {
          fullName: user.name,
        },
        app_metadata: {
          enableLogoCustomize: user.enableLogoCustomize || false,
          role: user.role === RoleUser.ADMIN ? Role.RAMMP_ADMIN : null,
          report_credit: user?.reportCredits || 0,
          enableLeadGenTool: user.enableLeadGenTool || false,
        },
        password: user.password,
      };
      if (isLoginWithGoogle) delete body.password;

      const res = await mutateAsync({
        id: user_id,
        body,
      });
      if (res) {
        toastSuccess('Update user successfully');
      } else {
        toastError('Error updating user');
      }
    }
  };

  return (
    <div className="w-96 bg-white rounded-lg shadow p-6 ">
      <div className="space-y-4">
        <h3>User Information</h3>
        <TextInput id="email" label="Email" value={user?.email} disabled />
        <TextInput
          id="name"
          label="Name"
          value={user?.name}
          onChange={(e) => {
            const { target } = e;
            onChange('name', target?.value);
          }}
        />
        <TextInput
          id="reportCredits"
          label="Report credit"
          value={user?.reportCredits}
          type="number"
          min={0}
          onChange={(e) => {
            const { target } = e;
            onChange(
              'reportCredits',
              parseInt(target?.value) > 0 ? parseInt(target?.value) || 0 : 0,
            );
          }}
        />
        <p className="mb-4 text-content-primary font-medium">
          Lead Gen Tool widget{' '}
          {widget ? (
            <Link
              asRouterLink
              routerLinkProps={{ to: `/widgets/${widget.id}` }}
              className="!inline"
            >
              View widget
            </Link>
          ) : (
            <div className="text-content-secondary mt-4">
              User does not set up widget yet
            </div>
          )}
        </p>
        <p className="mb-4 text-content-primary font-medium">Role</p>
        <SelectInput
          options={[RoleUser.ADMIN, RoleUser.USER]}
          value={user?.role}
          onChange={(val) => onChange('role', val)}
        />
        {!isLoginWithGoogle && (
          <>
            <TextInput
              id="password"
              label="Password"
              type="password"
              placeholder="••••••••"
              value={user?.password}
              onChange={(e) => {
                const { target } = e;
                onChange('password', target.value);
              }}
            />
          </>
        )}

        <Button
          variant={ButtonVariant.primary}
          className="w-full flex justify-center"
          isLoading={isLoading}
          onClick={onUpdateUser}
        >
          Update User
        </Button>
      </div>
    </div>
  );
};

export default EditUserForm;
