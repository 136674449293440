import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import {
  ForwardRefExoticComponent,
  Fragment,
  ReactNode,
  RefAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import { Button, ButtonProps, ButtonVariant } from '../Button';
import { IconWrapper } from '../IconWrapper';
import _ from 'lodash';

const MAX_DIALOG_SIZE = 'max-w-[480px]';

export type ConfirmDialogProps = {
  className?: string;
  open?: boolean;
  handleClose?: VoidFunction;
  handleSubmit?: VoidFunction;
  title?: string;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  submitButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  children?: ReactNode;
};

export interface DialogRefType {
  open: (settings: ConfirmDialogProps) => void;
  close: () => void;
  updateSettings: (settings: ConfirmDialogProps) => void;
}

const ConfirmDialog: ForwardRefExoticComponent<
  ConfirmDialogProps & RefAttributes<DialogRefType>
> = forwardRef<DialogRefType, ConfirmDialogProps>(
  (
    {
      handleClose = () => {},
      handleSubmit = () => {},
      open = false,
      className = '',
      cancelButtonTitle = 'Cancel',
      submitButtonTitle = 'Submit',
      submitButtonProps = {
        variant: ButtonVariant.primary,
      },
      cancelButtonProps = {
        variant: ButtonVariant.outline,
      },
      title = '',
      children,
    },
    ref,
  ) => {
    const [settings, setSettings] = useState<ConfirmDialogProps>({
      open,
      handleClose,
      handleSubmit,
      className,
      cancelButtonTitle,
      submitButtonTitle,
      submitButtonProps,
      cancelButtonProps,
      title,
      children,
    });

    const latestSettings = useRef<ConfirmDialogProps>(settings);

    useEffect(() => {
      setSettings((prev) => ({ ...prev, open }));
    }, [open]);

    useEffect(() => {
      latestSettings.current = settings;
    }, [settings]);

    useImperativeHandle(
      ref,
      () => ({
        open: (newSettings: ConfirmDialogProps) => {
          setSettings({ ...newSettings, open: true });
        },
        close: () => {
          setSettings({ ...latestSettings.current, open: false });
        },
        updateSettings: (newSettings: ConfirmDialogProps) => {
          setSettings(_.merge({}, latestSettings.current, newSettings));
        },
      }),
      [],
    );

    return (
      <Transition appear show={settings.open ?? false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={settings.handleClose!}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 dark:bg-[#9ca3af1a]" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex justify-center p-4 text-center min-h-full items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cn(
                    MAX_DIALOG_SIZE,
                    'bg-surface-base border border-surface-div w-full overflow-hidden',
                    'transform rounded-lg text-left align-middle shadow-xl transition-all',
                    className,
                  )}
                >
                  <div className=" px-6 py-4 flex justify-between items-center">
                    <h2>{settings.title}</h2>
                    <IconWrapper
                      icon={CloseIcon}
                      className="cursor-pointer text-content-secondary"
                      onClick={settings.handleClose}
                    />
                  </div>
                  <div className="px-6 pb-4">{settings.children}</div>
                  <div className="h-fit flex justify-end gap-2 px-6 py-4">
                    <Button
                      onClick={settings.handleClose}
                      {...settings.cancelButtonProps}
                    >
                      {settings.cancelButtonTitle}
                    </Button>
                    {settings.handleSubmit && (
                      <Button
                        onClick={settings.handleSubmit}
                        {...settings.submitButtonProps}
                      >
                        {settings.submitButtonTitle}
                      </Button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  },
);

ConfirmDialog.displayName = 'ConfirmDialog';
export { ConfirmDialog };
