import { useGetOverviewData } from 'apis/overview';
import { Loader, LoaderSize } from 'components/common';
import React from 'react';

const Home = () => {
  const { data, isLoading } = useGetOverviewData();
  console.log(data);
  return (
    <div className="p-6">
      <h1 className="mb-4">Welcome to Rammp Admin!</h1>

      {isLoading ? (
        <Loader size={LoaderSize.xxl} />
      ) : (
        <div className="flex gap-4">
          <div className="w-[200px] h-[100px] shadow-sm border rounded p-4">
            <h4 className="mb-4">Total Users</h4>
            <h1>{data?.data?.totalUsers}</h1>
          </div>
          <div className="w-[200px] h-[100px] shadow-sm border rounded p-4">
            <h4 className="mb-4">Total Brands (no archived)</h4>
            <h1>{data?.data?.totalBrands}</h1>
          </div>
          <div className="w-[200px] h-[100px] shadow-sm border rounded p-4">
            <h4 className="mb-4">Total Reports generated</h4>
            <h1>{data?.data?.totalReports}</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
