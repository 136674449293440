import { useMutation, useQuery, useQueryClient } from 'react-query';
import baseApi from './base';
import { User } from './users';
import { Report } from './brands';

export type Domain = {
  // Domain that whitelist in our system
  url: string;
};

export type Widget = {
  id: string;
  user_id: string;
  domains: Domain[];
  user: User;
  agencyName:string;
  agencyUrl: string;
  reports: Report[];
};

export const GET_WIDGETS_KEY = 'GET/widgets';
export const useGetWidgets = () => {
  return useQuery([GET_WIDGETS_KEY], async () => {
    const { data } = await baseApi.get(`/admin/widgets`);
    return data?.data as Widget[];
  });
};

export const GET_WIDGET_BY_ID_KEY = 'GET/widget';
export const useGetWidgetById = (id: string) => {
  return useQuery([GET_WIDGET_BY_ID_KEY, id], async () => {
    const { data } = await baseApi.get(`/admin/widgets/${id}`);
    return data?.data as Widget;
  });
};

export const UPDATE_WIDGET_BY_ID_KEY = 'UPDATE/widget';
export const useUpdateWidgetById = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    [UPDATE_WIDGET_BY_ID_KEY],
    async (payload: Partial<Widget>) => {
      const { data } = await baseApi.put(`/admin/widgets/${id}`, payload);
      return data as Widget;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_WIDGETS_KEY]);
        queryClient.invalidateQueries([GET_WIDGET_BY_ID_KEY]);
      },
    },
  );
};