import React, { useMemo, useState } from 'react';
import { Column, Pagination, Row, Table } from 'components/common';
import { useNavigate } from 'react-router-dom';
import { useGetBrands } from 'apis/brands';

const LIMIT = 50;
const Brands = () => {
  const [skip, setSkip] = useState(0);

  const { data, isLoading } = useGetBrands();

  const navigate = useNavigate();
  const columns: Column[] = [
    {
      id: 'id',
      label: 'Id',
      className: 'w-60',
    },
    {
      id: 'url',
      label: 'Brand Url',
      className: 'w-64',
    },
    {
      id: 'owner_id',
      label: 'Owner',
      className: 'w-64',
    },

    {
      id: 'reports',
      label: 'Total reports',
      customCellRender(value, row) {
        return value?.length || 0;
      },
      className: 'w-32',
    },
    {
      id: 'is_archived',
      label: 'Archived',
      customCellRender(value) {
        return value ? 'Yes' : 'No';
      },
    },
    {
      id: 'edit',
      label: '',
    },
    {
      id: 'delete',
      label: '',
    },
  ];
  const sortedData = useMemo(() => {
    return data?.sort((a, b) => a.id.localeCompare(b.id));
  }, [data]);

  const brands = useMemo(() => {
    return sortedData?.slice(skip, skip + LIMIT) || [];
  }, [sortedData, skip]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">Brands</h1>
      <div>
        <Table
          className="mt-5 w-full overflow-y-auto"
          classNameTable="w-full"
          classNameThead="sticky top-0 bg-surface-base text-left"
          columns={columns}
          rows={brands}
          loading={isLoading}
          onRowClick={(row: Row) => navigate(`/brands/${row.id}`)}
        />
        <Pagination
          className="mt-5"
          total={sortedData?.length || 0}
          skip={skip}
          limit={LIMIT}
          handlePageChange={(page) => {
            setSkip(page * LIMIT);
          }}
        />
      </div>
    </div>
  );
};

export default Brands;
