import React, {
  FunctionComponent,
  ButtonHTMLAttributes,
  ReactNode,
} from 'react';
import cn from 'classnames';
import { IconSize, IconWrapper } from '../IconWrapper';
import { activeStyle } from 'styles/constants';

export enum IconButtonSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum IconButtonVariant {
  primary = 'primary',
  outline = 'outline',
  text = 'text',
  danger = 'danger',
  link = 'link',
}

const BUTTON_SIZE_MAPPING = {
  [IconButtonSize.sm]: 'w-4 h-4 rounded',
  [IconButtonSize.md]: 'w-5 h-5 rounded',
  [IconButtonSize.lg]: 'w-6 h-6 rounded',
  [IconButtonSize.xl]: 'w-9 h-9 rounded-md',
};

const ICON_SIZE_MAPPING = {
  [IconButtonSize.sm]: IconSize.xxs,
  [IconButtonSize.md]: IconSize.xs,
  [IconButtonSize.lg]: IconSize.sm,
  [IconButtonSize.xl]: IconSize.md,
};

const COUNTER_STYLE_MAPPING = {
  [IconButtonSize.sm]: 'text-xs leading-none left-[calc(100%-2px)] top-0',
  [IconButtonSize.md]: 'text-xs leading-none left-[calc(100%-4px)] top-0',
  [IconButtonSize.lg]: 'text-xs leading-none left-[calc(100%-6px)] top-0.5',
  [IconButtonSize.xl]: 'text-sm left-[calc(100%-8px)] top-0.5',
};

const getVariantStyles = (variant: IconButtonVariant, disabled: boolean) => {
  switch (variant) {
    case IconButtonVariant.primary:
      return cn(
        !disabled
          ? 'text-content-primary-reverse bg-accent-main hover:bg-accent-hover hover:shadow-md dark:bg-content-primary dark:hover:bg-surface-overlay-reverse'
          : 'bg-action-hover text-content-tetriary',
      );
    case IconButtonVariant.outline:
      return cn(
        'border-surface-div border',
        !disabled
          ? 'text-content-primary bg-surface-overlay dark:bg-transparent hover:bg-action-hover dark:hover:bg-action-hover'
          : 'bg-action-hover border-transparent text-content-tetriary',
      );
    case IconButtonVariant.text:
      return cn(
        'text-content-tetriary',
        !disabled
          ? 'hover:bg-action-hover hover:text-content-primary'
          : 'text-content-tetriary',
      );
    case IconButtonVariant.danger:
      return cn(
        !disabled
          ? 'text-content-primary-reverse dark:text-content-primary bg-error-main hover:bg-error-hover hover:shadow-md'
          : 'bg-action-hover text-content-tetriary',
      );
    case IconButtonVariant.link:
      return cn(
        !disabled
          ? 'text-accent-main hover:bg-action-hover'
          : 'text-content-tetriary',
      );
    default:
      return '';
  }
};

export type IconButtonProps = {
  className?: string;
  classNameWrapperIcon?: string;
  disabled?: boolean;
  variant?: IconButtonVariant;
  isLoading?: boolean;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  size?: IconButtonSize;
  iconSize?: IconSize;
  showBadge?: boolean;
  badge?: string | ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton: FunctionComponent<IconButtonProps> = ({
  className = '',
  classNameWrapperIcon = '',
  icon,
  disabled = false,
  isLoading = false,
  variant = IconButtonVariant.primary,
  size = IconButtonSize.md,
  iconSize,
  showBadge,
  badge,
  ...props
}) => {
  return (
    <button
      className={cn(
        'relative group flex items-center justify-center duration-250',
        getVariantStyles(variant, disabled || isLoading),
        BUTTON_SIZE_MAPPING[size],
        activeStyle,
        className,
      )}
      disabled={disabled}
      {...props}
    >
      <IconWrapper
        icon={icon}
        size={iconSize || ICON_SIZE_MAPPING[size]}
        className={classNameWrapperIcon}
      />
      {showBadge && (
        <span
          className={cn(
            'absolute z-1 text-content-primary',
            COUNTER_STYLE_MAPPING[size],
          )}
        >
          {badge}
        </span>
      )}
    </button>
  );
};
