import React, {
  FunctionComponent,
  ReactNode,
} from 'react';
import cn from 'classnames';
import { Logo } from 'components/common';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/view-grid.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/dollar.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarItem from './SidebarItem';

export interface SidebarProps {}

type SidebarOption = {
  key: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  url?: string;
  suffixComponent?: ReactNode;
};

const sidebarOptions: SidebarOption[] = [
  {
    key: 'dashboard',
    icon: DashboardIcon,
    label: 'Dashboard',
    url: '/',
  },
  {
    key: 'user',
    icon: UserIcon,
    label: 'User Management',
    url: '/users',
  },
  {
    key: 'brands',
    icon: TagIcon,
    label: 'Brands',
    url: '/brands',
  },
  {
    key: 'plans',
    icon: DollarIcon,
    label: 'Plans',
    url: '/plans',
  },
  {
    key: 'widgets',
    icon: DocumentIcon,
    label: 'Lead Gen Widgets',
    url: '/widgets',
  },
];

const Sidebar: FunctionComponent<SidebarProps> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        'fixed left-0 top-0 w-sidebar h-full px-4 py-5',
        'border-r border-black bg-surface-base border-r border-surface-div',
      )}
    >
      <div className="flex items-center justify-between mb-4">
        <Logo />
      </div>
      {sidebarOptions.map((i) => (
        <SidebarItem
          active={i.url === pathname}
          key={i.key}
          prefixIcon={i.icon}
          label={i.label}
          suffixComponent={i.suffixComponent}
          url={i.url}
        />
      ))}
      <SidebarItem
        label={'Logout'}
        onClick={() => {
          localStorage.clear();
          navigate('/login');
        }}
      />
    </div>
  );
};

export default Sidebar;
