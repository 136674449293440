import React from 'react';
import { Routes as Switch } from 'react-router-dom';
import ThemeProvider from 'contexts/ThemeContext';
import routes from 'router/Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AuthProvider from 'contexts/AuthContext';
import { AppLayout } from './layouts';

const App = () => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!isLoggedIn) localStorage.setItem('isLoggedIn', 'false');

  return (
    <ThemeProvider>
      <AuthProvider>
        <AppLayout>
          <Switch>{routes}</Switch>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            theme="light"
            pauseOnHover
          />
        </AppLayout>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
