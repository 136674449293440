import React, { useMemo, useState } from 'react';
import { Column, Pagination, Row, Table } from 'components/common';
import { useNavigate } from 'react-router-dom';
import { useGetPlans } from 'apis/plans';
import { centToStringDollar } from 'helpers';

const LIMIT = 50;
const Plans = () => {
  const [skip, setSkip] = useState(0);

  const { data, isLoading } = useGetPlans();

  const navigate = useNavigate();
  const columns: Column[] = [
    {
      id: 'plan_type',
      label: 'Id',
      className: 'w-50',
    },
    {
      id: 'name',
      label: 'Name',
      className: 'w-50',
    },
    {
      id: 'is_active',
      label: 'Is active',
      customCellRender(value) {
        return value ? 'Yes' : 'No';
      },
    },
    {
      id: 'is_private',
      label: 'Is private plan',
      customCellRender(value) {
        return value ? 'Yes' : 'No';
      },
    },
    {
      id: 'monthly_price',
      label: 'Monthly price',
      customCellRender(value) {
        return '$' + centToStringDollar(value);
      },
    },
    {
      id: 'yearly_price',
      label: 'Yearly price',
      customCellRender(value) {
        return '$' + centToStringDollar(value);
      },
    },
    {
      id: 'whiteLabel',
      label: 'White Label',
      customCellRender(_, row) {
        return row?.features?.whiteLabel ? 'enabled' : 'disabled';
      },
    },
    {
      id: 'leadGen',
      label: 'Lead Gen Tool',
      customCellRender(_, row) {
        return row?.features?.leadGen ? 'enabled' : 'disabled';
      },
    },
    {
      id: 'totalBrands',
      label: 'Total brand urls',
      customCellRender(_, row) {
        return row?.features?.totalBrands || 0;
      },
    },
  ];

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => a.id.localeCompare(b.id));
  }, [data]);

  const plans = useMemo(() => {
    return sortedData?.slice(skip, skip + LIMIT) || [];
  }, [sortedData, skip]);

  return (
    <div className="w-full relative p-6">
      <h1 className="mb-4 pb-4 border-b-2">Plans</h1>
      <div>
        <Table
          className="mt-5 w-full overflow-y-auto"
          classNameTable="w-full"
          classNameThead="sticky top-0 bg-surface-base text-left"
          columns={columns}
          rows={plans}
          loading={isLoading}
          onRowClick={(row: Row) => navigate(`/plans/${row.id}`)}
        />
        <Pagination
          className="mt-5"
          total={sortedData?.length || 0}
          skip={skip}
          limit={LIMIT}
          handlePageChange={(page) => {
            setSkip(page * LIMIT);
          }}
        />
      </div>
    </div>
  );
};

export default Plans;
